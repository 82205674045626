import './TopNav.scss';
import mchpLogo from '../../images/microchip.png';
import awsLogo from '../../images/aws-logo.svg';
import gcpLogo from '../../images/google-cloud-logo.svg';
import { CLOUDS } from '../../Constants';

type TopNavProps = {
    cloud: CLOUDS
};

export default function TopNav(props: TopNavProps) {
    
    let makeCloudLogoLink = (url: string, image: any) => {
        return (
            <a href={ url } rel="noopener noreferrer" target="_blank">
                <img className="cloud-img" src={ image } alt="Microchip"/>
            </a>
        );
    };
    
    let cloudLogoLink;
    
    switch (props.cloud) {
        case CLOUDS.aws:
            cloudLogoLink = makeCloudLogoLink('https://aws.amazon.com/', awsLogo);
            break;

        case CLOUDS.gcp:
            cloudLogoLink = makeCloudLogoLink('https://cloud.google.com/', gcpLogo);
            break;

        default:
            cloudLogoLink = null;
    }

    return (
        <nav className="topnav content-wrapper">
            <a href="/" rel="noopener noreferrer">
                <img className="topnav-img" src={ mchpLogo } alt="Microchip"/>
            </a>

            { cloudLogoLink }
        </nav>
    );
}
