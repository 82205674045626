import * as React from 'react';
import './WifiSetupTile.scss';

type WifiSetupTileProps = {
    onDownloadClick: React.MouseEventHandler<HTMLButtonElement>
    hasWPA3?: boolean
};

type WifiSetupTileState = {
    ssid: string,
    networkType: string,
    password: string,
    showPassword: boolean,
    onDownloadClick: React.MouseEventHandler<HTMLButtonElement>
};

export default class WifiSetupTile extends React.Component<WifiSetupTileProps, WifiSetupTileState> {
    hasWPA3?: boolean;

    constructor(props: WifiSetupTileProps) {
        super(props);
        this.hasWPA3 = props.hasWPA3;

        this.state = { 
            ssid: '',
            networkType: '1',
            password: '',
            showPassword: false,
            onDownloadClick: props.onDownloadClick
        };
    }

    onSSIDChange(event: any) {
        this.setState({ssid: event.target.value});
    }

    onPasswordChange(event: any) {
        this.setState({password: event.target.value});
    }

    onNetworkTypeChange(event: any) {
        this.setState({networkType: event.target.value});
    }

    onShowPasswordClick() {
        this.setState({ showPassword: !this.state.showPassword });
    }

    onDownloadClick(event: any) {
        event.preventDefault();

        // Download it
        const blob = new Blob([`CMD:SEND_UART=wifi ${this.state.ssid},${this.state.password},${this.state.networkType}\r\n`]);
        let url = URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'WIFI.CFG';
        a.click();
        this.state.onDownloadClick(event);
    }

    public render() {
        const nt = this.state.networkType;
        return (
            <div className="tile wifisetuptile">
                <h4>Wireless Network Login</h4>

                <div className="mui-textfield ">
                    <input placeholder="Wireless Network Name" onChange={(e) => this.onSSIDChange(e)} required />
                </div>

                <em>Your Wi-Fi® information is not transmitted anywhere—the config file is generated in your browser.</em>

                <fieldset className="wifi-form" onChange={(e) => this.onNetworkTypeChange(e)}>
                    <legend className="wifi-form-label">Network Type</legend>
                    <label className={ nt === '1' ? 'checked' : '' }><input id="network-type-open" value="1" name="network-type" type="radio" checked={ nt === '1' } readOnly /> Open</label>
                    <label className={ nt === '2' ? 'checked' : '' }><input id="network-type-wpa"  value="2" name="network-type" type="radio" checked={ nt === '2' } readOnly /> WPA/WPA2</label>
                    { this.hasWPA3 ? <label className={ nt === '4' ? 'checked' : '' }><input id="network-type-wpa3"  value="4" name="network-type" type="radio" checked={ nt === '4' } readOnly /> WPA3</label> : null}                                    
                    <label className={ nt === '3' ? 'checked' : '' }><input id="network-type-wep"  value="3" name="network-type" type="radio" checked={ nt === '3' } readOnly /> WEP</label>
                </fieldset>

                <div className={nt === '1' ? ' hidden' : 'mui-textfield'} >
                    <input placeholder="Network Password" 
                        type={ this.state.showPassword ? 'text' : 'password' }
                        onChange={(e) => this.onPasswordChange(e)}
                    />
                    <div className="toggle-pw-vis">
                        <button type="button" id="toggle-pw-vis" onClick={() => this.onShowPasswordClick()}>Show password</button>
                    </div>
                </div>

                <div className="wifi-form-buttons">
                    <button type="button" className="btn red" onClick={(e) => this.onDownloadClick(e)}>Download Configuration</button>
                </div>
            </div>
        );
    }
}
