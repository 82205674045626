import Flicking, { ViewportSlot } from '@egjs/react-flicking';
import { AutoPlay, Pagination } from '@egjs/flicking-plugins';
import TopNav from '../../components/TopNav/TopNav';
import { ConnectionType, KitBox } from './KitBox';
import { Kit } from './Kit';
import { BOARDS, CLOUDS } from '../../Constants';
import avrIotWx from '../../images/avr-iot-wx-angle.jpg';
import picIotWx from '../../images/avr-iot-wx-angle.jpg';
import cellularMini from '../../images/avr-iot-cellular-mini.png';
import pic32mzIotWx from '../../images/pic32mz-angle.jpg';
import pic32cm_ls60_curiosity_pro from '../../images/pic32cm_ls60_curiosity_pro.png';
import pic32cm_ls60_rd from '../../images/pic32cm_ls60_rd.jpg';
import samIotWxv2 from '../../images/sam-iot-v2.jpg';
import wfi32Iot from '../../images/pic32mz_wfi32.png';

import '../../Landingpage.scss';
import './Frontpage.scss';
import '../../components/Welcome/Welcome.scss';
import '@egjs/react-flicking/dist/flicking.css';
import '@egjs/flicking-plugins/dist/pagination.css';
import { Footer } from '../../components/Footer/Footer';

const kits: Kit[] = [
    {
        image: avrIotWx,
        kitnames: ['AVR-IoT WA Development Board'],
        link: '/avr-iot',
        connection: ConnectionType.wifi,
        clouds: [CLOUDS.aws]
    }, {
        image: picIotWx,
        kitnames: ['PIC-IoT WA Development Board'],
        link: '/pic-iot',
        connection: ConnectionType.wifi,
        clouds: [CLOUDS.aws, CLOUDS.azure]
    }, {
        image: cellularMini,
        kitnames: ['AVR-IoT Cellular Mini'],
        link: '/avr-iot-cellular-mini',
        connection: ConnectionType.ltem,
        clouds: [CLOUDS.aws]
    }, {
        image: pic32mzIotWx,
        kitnames: ['PIC32 WFI32E Curiosity Board'],
        link: '/pic32mzw1',
        connection: ConnectionType.wifi,
        clouds: [CLOUDS.aws]
    }, {
        image: wfi32Iot,
        kitnames: ['WFI32-IoT Board'],
        link: '/pic32mzw1',
        connection: ConnectionType.wifi,
        clouds: [CLOUDS.aws]
    }
];

const kitBoxes = kits.map((k,i) =>
    <KitBox key={i} kitnames={k.kitnames}
        image={<KitImage src={k.image} />}
        link={k.link}
        connection={k.connection}
        clouds={k.clouds}
    />
);

const demos: Kit[] = [
    {
        image: samIotWxv2,
        kitnames: ['AWS Cloud IoT Core Application on SAM-IoT Wx v2 Development Board'],
        link: 'https://github.com/Microchip-MPLAB-Harmony/reference_apps/blob/master/apps/sam_d21_iot_v2/samiot2_aws_cloud_core/readme.md',
        connection: ConnectionType.wifi,
        clouds: [CLOUDS.aws]
    }, {
        image: samIotWxv2,
        kitnames: ['Microsoft Azure Cloud IoT Core Application on SAM-IoT Wx v2 Development Board'],
        link: 'https://github.com/Microchip-MPLAB-Harmony/reference_apps/blob/master/apps/sam_d21_iot_v2/samiot2_azure_cloud_core/readme.md',
        connection: ConnectionType.wifi,
        clouds: [CLOUDS.azure]
    }, {
        image: pic32cm_ls60_curiosity_pro,
        kitnames: ['AWS Cloud IoT Core Application on PIC32CM LS60 Curiosity Pro Evaluation Kit'],
        link: 'https://github.com/Microchip-MPLAB-Harmony/reference_apps/tree/master/apps/pic32cm_ls60_curiosity_pro/pic32cm_ls60_aws_iot_core',
        connection: ConnectionType.wifi,
        clouds: [CLOUDS.aws]
    }, {
        image: pic32cm_ls60_curiosity_pro,
        kitnames: ['Secure IoT Gateway on PIC32CM LS60 Curiosity Pro Evaluation Kit'],
        link: 'https://github.com/Microchip-MPLAB-Harmony/reference_apps/blob/master/apps/pic32cm_ls60_curiosity_pro/pic32cm_ls60_secure_iot_gateway/readme.md',
        connection: ConnectionType.wifi,
        clouds: [CLOUDS.aws]
    }, {
        image: pic32cm_ls60_rd,
        kitnames: ['Smart Lock Application on PIC32CM LS60 Smart Lock Reference Board'],
        link: 'https://github.com/Microchip-MPLAB-Harmony/reference_apps/blob/master/apps/pic32cm_ls60_rd/pic32cm_ls60_rd_smart_lock/readme.md',
        connection: ConnectionType.wifi,
        clouds: [CLOUDS.aws]
    }
];

const demoBoxes = demos.map((k,i) =>
    <KitBox key={i} kitnames={k.kitnames}
        image={<KitImage src={k.image} />}
        link={k.link}
        connection={k.connection}
        clouds={k.clouds}
    />
);

function KitImage(props: any) {
    return <img src={props.src} alt="Kit" style={props.style} />;
}


function Welcome() {
    const plugins = [
        new AutoPlay({ duration: 5000, direction: 'NEXT', stopOnHover: true }),
        new Pagination({ type: 'bullet' })
    ];

    return (
        <div className="welcome">
            <div className="content-wrapper">
                <div className="content">
                    <div className="left">
                        <h2>IoT with Microchip</h2>
                        This page provides an initial sandbox experience for the various
                        IoT Development Kits from Microchip. Select your kit from the
                        offering below, or open the "CLICKME.HTM" file in the "Curiosity"
                        drive that appears when a board is connected to your computer
                        through USB.
                    </div>
                    <div className="inset">
                        <h3>Featured boards</h3>
                        <Flicking
                            plugins={plugins}
                            align = "prev"
                            circular={true}
                            panelsPerView={1}
                        >
                            <div key="panel0">{kitBoxes[0]}</div>
                            <div key="panel3">{kitBoxes[3]}</div>

                            <ViewportSlot>
                                <div className="flicking-pagination"></div>
                            </ViewportSlot>
                        </Flicking>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default function Frontpage() {
    const links = [ {
        header: 'Other IoT Solutions & Architecture',
        links: [
            {
                text: 'All Microchip IoT Solutions',
                url:  'https://www.microchip.com/en-us/solutions/internet-of-things'
            }
        ]
    }];

    return (
        <div className="frontpage landingpage">
            <TopNav cloud={CLOUDS.none} />
            <Welcome />
            <div className="content-wrapper">
                <h3>Microchip Sandbox-Enabled Demo Boards</h3>
                <div className="kitwrapper">
                    {kitBoxes}
                </div>

                <h3>Applications Not Supporting Microchip Sandbox</h3>
                <div className="kitwrapper">
                    {demoBoxes}
                </div>
            </div>
            <Footer links={links} board={BOARDS.avrIot}/>
        </div>
    );
}
