import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/database';
import { DeviceManager } from './DeviceManagerFactory';
import { ConfigDef } from '../Config';

/**
 * manages device's connection to firebase
 */
export default class FbDeviceManager implements DeviceManager {
    firebaseConfig: ConfigDef;
    device: string;
    maxDataAge: number;
    offset: number;
    firebaseUrl: string | undefined;
    offsetSet: boolean;
    firebaseInitialized: boolean;
    firebase: firebase.app.App | null = null;
    updateDevice: firebase.functions.HttpsCallable | null = null;
    onMessage: any;
    firstSample: boolean;   // Filter out the very first sample, 
                            // becuse that may be old data from the database,
                            // even if the kit is offline.

    constructor(opts: any) {
        this.firebaseConfig = opts.firebaseConfig;
        this.device = opts.device || null;
        this.maxDataAge = opts.maxDataAge;
        this.offset = opts.offset || 0;
        this.firebaseUrl = opts.firebaseUrl;
        this.offsetSet = !!opts.offset;
        this.firebaseInitialized = false;
        this.firstSample = true;
    }

    start = async () => {
        this.firebase = firebase.initializeApp(this.firebaseConfig);
        this.updateDevice = firebase.functions().httpsCallable('updateDevice');
        this.firebaseInitialized = true;
    };

    setDevice = (uid: string) => { this.device = uid.toUpperCase(); };

    setOnMessage = (onMessage: any) => { this.onMessage = onMessage; };

    getFirebaseOffset = async () => {
        // get time difference between client and server
        try {
            const offsetData = await firebase.database().ref('/.info/serverTimeOffset').once('value');
            const offset = offsetData.val();
            this.offset = offset;
            this.offsetSet = true;
            return offset;
        } catch (e) {
            return 0;
        }
    };

    sendDeviceConfig = (msg: any) => {
        if (this.updateDevice) {
            return this.updateDevice({ deviceId: this.device, msg }); 
        } else { 
            return null;
        }
    };

    connect = async () => {
        // initialize firebase
        if (!this.firebaseInitialized) {
            this.start();
        }

        // sync client and server
        if (!this.offsetSet) {
            await this.getFirebaseOffset();
        }

        if (!this.device) {
            return;
        }

        // get latest update from device
        firebase.database()
            .ref(`${this.firebaseUrl}/${this.device}`)
            .limitToLast(1)
            .on('value', (snapshot) => {
                if (snapshot && snapshot.val()) {
                    if (!this.firstSample) {
                        let data = Object.keys(snapshot.val())[0];
                        this.onMessage(snapshot.val()[data]);
                    } else {
                        this.firstSample = false;
                    }
                }
            });
    };

    disconnect = () => {
        firebase.database().ref(`${this.firebaseUrl}/${this.device}`).off('value');
    };

    isStale(snapVal: any) {
        const snapArray = Object.keys(snapVal);
        const lastKey = snapArray[snapArray.length - 1];
        const mostRecent = snapVal[lastKey];
        const estServerTime = new Date().getTime() + this.offset;
        const dataAge = Math.floor((estServerTime - mostRecent.time) / 1000); // age of data in seconds
        return dataAge > this.maxDataAge;
    };
}
