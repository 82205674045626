import { ChangeEvent, useState } from 'react';
import classnames from 'classnames';
import { EditText, onSaveProps } from 'react-edit-text';
import SettingsButton from './SettingsButton';
import TextBox from './TextBox';
import DeleteButton from './DeleteButton';
import './SliderControl.scss';

type ConfigSliderProps = {
    id: number;
    showConfig: Boolean;
    onValueChange: (id: number, value: number) => void;
}

const ConfigSlider = (props: ConfigSliderProps) => {
    let [min, setMin] = useState<Number>(1);
    let [max, setMax] = useState<Number>(100);
    let [step, setStep] = useState<Number>(1);

    const handleSliderValChange = (evt: ChangeEvent<HTMLInputElement>) => {
        let value = Number.parseInt(evt.target.value);
        props.onValueChange(props.id, value);
    };

    let config = (
        <div className={classnames('config', {hidden: !props.showConfig})}>
            <div>Min <TextBox type="number" className="min-val" value={min.toString()}
                        onChange={ (evt) => {setMin(Number.parseInt(evt.target.value));}} /></div>
            <div>Step <TextBox type="number" className="step-val" value={step.toString()}
                        onChange={ (evt) => {setStep(Number.parseInt(evt.target.value));}} /></div>
            <div>Max <TextBox type="number" className="max-val" value={max.toString()}
                        onChange={ (evt) => {setMax(Number.parseInt(evt.target.value));}} /></div>
        </div>
    );

    return (
        <div className="ConfigSlider">
            <div>
                <input type="range" min={min.toString()} max={max.toString()} step={step.toString()} className="input-slider" onChange={handleSliderValChange} />
            </div>
            { props.showConfig ? config : null }
        </div>
    );
};

type SliderControlProps = {
    label: string;
    id: number;
    value: number;
    onValueChange: (id: number, value: number) => void;
    onLabelChange: (id: number, label: string) => void;
    onDelete: (id: number) => void;
};

export default function SliderControl(props: SliderControlProps) {
    let [showSettings, setShowSettings] = useState<Boolean>(true);

    const handleLableSave = (saveProps: onSaveProps) => {
        let { value } = saveProps;
        props.onLabelChange(props.id, value);
    };

    let handleToggleSettings = () => {
        setShowSettings(!showSettings);
    };

    return (
        <div className="SliderControl">
            <EditText
                className="label"
                name={props.label}
                defaultValue={props.label}
                placeholder='Enter a name'
                onSave={handleLableSave}
                style={{width: '200px'}}
                inline
            />
            <SettingsButton show={showSettings} onClick={handleToggleSettings} />
            <ConfigSlider id={props.id} showConfig={showSettings} onValueChange={props.onValueChange} />
            <TextBox type="number" className="input-value" onChange={(event) => props.onValueChange(props.id, Number.parseInt(event.target.value))} value={props.value.toString()} />
            <DeleteButton onClick={ () => props.onDelete(props.id) } />
        </div>
    );
}
