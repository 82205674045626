import './Footer.scss';
import mchplogo from '../../images/microchip-white.svg';
import { OutboundLink } from '../Links/Links';
import { BOARDS } from '../../Constants';

export type Links = {
    header: string;
    links: Link[]
}

export type Link = {
    text: string | null;
    url: string | null;
}

type FooterProps = {
    links: Links[];
    board: BOARDS;
}

export function Footer(props: FooterProps) {
    function lia(link: Link) {
        if (link.url != null) {
            return (
                <li key={link.text?.replace(' ', '')}>
                    <OutboundLink to={link.url} eventLabel={'Footer link for' + props.board}>{link.text}</OutboundLink>
                </li>
            );
        } else  {
            return null;
        }
    }

    function footerLinks(links: Links) {
        let linklistitems = links.links.filter(l => l.text != null).map(l => lia(l));

        return (
            <div key={links.header.replace(' ', '')} className="footer-links">
                <h3 className="header">{links.header}</h3>
                <ul>
                    {linklistitems}
                </ul>
            </div>
        );
    }

    let footerlinks = props.links.map(l => footerLinks(l));

    return (
        <div className="footer">
            <section className="content-wrapper">
                {footerlinks}
                <div className="footer-logo">
                    <a href="https://microchip.com" target="_blank" rel="noopener noreferrer">
                        <img src={mchplogo} alt="logo" />
                    </a>
                </div>
            </section>
        </div>
    );
}
