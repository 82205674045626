import Collapse from 'rc-collapse';
import 'rc-collapse/assets/index.css';
import './SimpleExpander.scss';
import { Key, useState } from 'react';
import { ExpandIcon } from './Icon';
import classnames from 'classnames';


type SimpleExpanderProps = {
    collapsedTitle: string;
    expandedTitle: string;
    class: string;
    iconColor: string;
    iconFillColor: string;
    children: any;
}

export default function SimpleExpander(props: SimpleExpanderProps) {
    let [header, setHeader] = useState(props.collapsedTitle);
    
    function collapseChanged(key: Key | Key[]): void {
        if (Array.isArray(key)) {
            if (key.length > 0) {
                setHeader(props.expandedTitle);
            } else {
                setHeader(props.collapsedTitle);
            }
        }
    }
    
    function Icon(iprops: object) {
        let {isActive} = iprops as {isActive: boolean};
        return ExpandIcon(isActive, props.iconColor, 1, props.iconFillColor);
    }
    
    return (
        <Collapse className={classnames( 'collapse-simple', props.class)} onChange={collapseChanged} expandIcon={Icon}>
            <Collapse.Panel header={header} >
                {props.children}
            </Collapse.Panel>
        </Collapse>
    );
}
SimpleExpander.defaultProps = {class: '', iconColor: 'black', iconFillColor: 'white' };