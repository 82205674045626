import { useState } from 'react';
import { SimpleSlider } from '../../components/Controls/SimpleSliderControl';
import { LedToggleButton } from '../../components/LedToggleButton/LedToggleButton';
import Visualizer from '../../components/Visualizer/Visualizer';
import { CLOUDS } from '../../Constants';
import './FreeRTOSDashboard.scss';

type FreeRTOSDashboardProps = {
    thingName: string;
    data: any;
    cloud: CLOUDS;
    isStreaming: boolean;
    onSubmit: (data: any) => void;
}

export function FreeRTOSDashboard(props: FreeRTOSDashboardProps) {
    const [duration, setDuration] = useState<number>(1);
    const [freq, setFreq] = useState<number>(1000);
    const [verboseLogClicked, setVerboseLogClicked] = useState<boolean>(false);

    function handleStartStreaming() {
        props.onSubmit({
            cmd: 'stream',
            opts: { duration: duration * 60, freq }
        });
    }

    function handleVerboseLog() {
        setVerboseLogClicked(true);
        props.onSubmit({
            cmd: 'verbose_logs',
            opts: {}
        });
    }

    const handleLedClick = (ledName: string, state: number) => {
        props.onSubmit({
            cmd: 'set_led',
            opts: {
                'led': ledName,
                'state': state
            }
        });
    };

    const visualizer = props.isStreaming ?
        <Visualizer thingName={props.thingName} data={props.data} cloud={props.cloud} />
        : <section className='content-wrapper'><p>The board is not connected.</p></section>;

    return (
        <section className='Dashboard free-rtos'>
            <section className='content-wrapper'>
                <h2>Low Power FreeRTOS Demo Sandbox</h2>
            </section>

            <div className="content">
                {visualizer}
            </div>

            <section className='content-wrapper'>
                <h2>Interact with the Board</h2>
                <h3>Stream Sensor Data</h3>
                <p>
                    This demo focuses on keeping the power usage to a minimum. The firmware connects to the network at intervals.
                    It will send a set of sensor readings, and receive configuration settings from the cloud. The interval can be configured.
                </p>
                <p>
                    The board has two sensors, one temperature sensor and one RGB sensor. They can be samepled many times during one
                    transmission interval. The sampling interval can also be configured.
                    
                    This cloud sandbox allows receive data from the board, and send settings back to the board. Use the below control panel
                    to set the two intervals. These settings will take effect the next time the board exchanges data.
                </p>

                <div className='slider'>
                    <div className='label'><b>Transmission interval:</b> {duration} minutes</div>
                    <SimpleSlider id={1} value={duration} min={1} max={10} step={1} onValueChange={(id: number, value: number) => { setDuration(value); }} />
                </div>
                <div className="slider-description">
                    The interval at which the firmware powers on the modem, connects to the cellular network and exchange data.
                </div>

                <div className='slider'>
                    <div className='label'><b>Sampling interval:</b> {freq} milliseconds</div>
                    <SimpleSlider id={2} value={freq} min={500} max={10000} step={5} onValueChange={(id: number, value: number) => { setFreq(value); }} />
                </div>
                <div className="slider-description">
                    The interval at which the firmware samples the sensors. There can be several sensor readings during one transmission interval.
                </div>

                <div className='stream-buttons'>
                    <button className="btn red" onClick={handleStartStreaming}>Update settings</button>
                    <button style={{ marginLeft: '10px' }} className="btn red-inverted" onClick={handleVerboseLog}>Set Logging to Verbose (Serial Output)</button>
                </div>
                {(() => {
                    if (verboseLogClicked) {
                        return (
                            <div style={{ justifyContent: 'end', display: 'flex', marginTop: '10px' }}>
                                <b>Starting verbose logging. See the serial output from the terminal at the bottom of the page.</b>
                            </div>
                        );
                    }
                })()}
            </section>
            <section className='content-wrapper'>
                <h3>Control Leds</h3>
                <p>Click on the buttons below to toggle the LEDs on the board.</p>
                <p><b>Note! The LEDs will not toggle instantly, but next time the board transmits/receives data.</b></p>
                <p><b>Tip: Be careful about sending too many messages, as this can overwhelm the receive buffer on the sandbox.</b></p>
                <div style={{ textAlign: 'center' }}>
                    <LedToggleButton ledName='USER' clickHander={handleLedClick} />
                    <LedToggleButton ledName='ERROR' clickHander={handleLedClick} />
                </div>
            </section>
        </section>
    );
}