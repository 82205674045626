import React, { useState } from 'react';
import './LedToggleButton.scss';
import { Lightbulb } from './Lightbulb';

export type LedToggleButtonProps = {
    ledName: string,
    clickHander: (ledName: string, state: number) => void
};

export function LedToggleButton(props: LedToggleButtonProps) {
    const [ledOn, setLedOn] = useState<boolean>(false);

    const fillColor = (): string => {
        if (ledOn) {
            switch (props.ledName) {
                case 'USER':
                    return 'yellow';
                case 'ERROR':
                    return 'red';
                default:
                    return 'none';
            }
        } else {
            return 'none';
        }
    };

    const clickLedHandler = () => {
        setLedOn((prevState) => {
            props.clickHander(props.ledName, !prevState ? 1 : 0);
            return !prevState;
        });
    };

    return (
        <div style={{ verticalAlign: 'top', display: 'inline-block', textAlign: 'center', margin: '10px' }}>
            <button className='btn lightbulb' onClick={clickLedHandler} >
                <Lightbulb fill={fillColor()} />
                <span style={{ display: 'block' }}>{props.ledName} LED</span>
            </button>
        </div>
    );
}