import { BOARDS } from './Constants';

interface FirebaseConfig {
    apiKey: string | undefined,
    authDomain: string | undefined,
    databaseURL: string | undefined,
    projectId: string | undefined,
    storageBucket: string | undefined,
    messagingSenderId: string | undefined,
}

export interface ConfigDef {
    ENV: string,
    FIREBASE_BASE_URL: Record<BOARDS, string | undefined>,
    FIREBASE_CONFIG: Record<BOARDS, FirebaseConfig | undefined>
    AWS_WEBSOCKET_HOST_URL: string | undefined,
    AWS_CONNECTION_SECRET: string | undefined
}

export const Config: ConfigDef = {
    ENV: process.env.NODE_ENV,

    FIREBASE_BASE_URL: {
        'avr-iot': process.env.REACT_APP_AVR_FIREBASE_BASE_URL,
        'avr-iot-cellular-mini':  process.env.REACT_APP_AVR_FIREBASE_BASE_URL,
        'pic-iot': process.env.REACT_APP_PIC_FIREBASE_BASE_URL,
        'sam-iot': process.env.REACT_APP_PIC_FIREBASE_BASE_URL,
        'pic32mzw1': undefined,
        'none': undefined
    },

    FIREBASE_CONFIG: {
        'avr-iot': {
            apiKey:            process.env.REACT_APP_AVR_FIREBASE_API_KEY,
            authDomain:        process.env.REACT_APP_AVR_FIREBASE_AUTH_DOMAIN,
            databaseURL:       process.env.REACT_APP_AVR_FIREBASE_DATABASE_URL,
            projectId:         process.env.REACT_APP_AVR_FIREBASE_PROJECT_ID,
            storageBucket:     process.env.REACT_APP_AVR_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_AVR_FIREBASE_MESSAGING_SENDER_ID
        },
        'avr-iot-cellular-mini': {
            apiKey:            process.env.REACT_APP_AVR_FIREBASE_API_KEY,
            authDomain:        process.env.REACT_APP_AVR_FIREBASE_AUTH_DOMAIN,
            databaseURL:       process.env.REACT_APP_AVR_FIREBASE_DATABASE_URL,
            projectId:         process.env.REACT_APP_AVR_FIREBASE_PROJECT_ID,
            storageBucket:     process.env.REACT_APP_AVR_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_AVR_FIREBASE_MESSAGING_SENDER_ID
        },
        'pic-iot': {
            apiKey:            process.env.REACT_APP_PIC_FIREBASE_API_KEY,
            authDomain:        process.env.REACT_APP_PIC_FIREBASE_AUTH_DOMAIN,
            databaseURL:       process.env.REACT_APP_PIC_FIREBASE_DATABASE_URL,
            projectId:         process.env.REACT_APP_PIC_FIREBASE_PROJECT_ID,
            storageBucket:     process.env.REACT_APP_PIC_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_PIC_FIREBASE_MESSAGING_SENDER_ID
        },
        'sam-iot': {
            apiKey:            process.env.REACT_APP_PIC_FIREBASE_API_KEY,
            authDomain:        process.env.REACT_APP_PIC_FIREBASE_AUTH_DOMAIN,
            databaseURL:       process.env.REACT_APP_PIC_FIREBASE_DATABASE_URL,
            projectId:         process.env.REACT_APP_PIC_FIREBASE_PROJECT_ID,
            storageBucket:     process.env.REACT_APP_PIC_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_PIC_FIREBASE_MESSAGING_SENDER_ID
        },
        'pic32mzw1': undefined,
        'none': undefined
    },

    AWS_WEBSOCKET_HOST_URL: process.env.REACT_APP_AWS_WEBSOCKET_HOST_URL,
    AWS_CONNECTION_SECRET:  process.env.REACT_APP_AWS_CONNECTION_SECRET
};
