import classnames from 'classnames';

type PulsarProps = {
    color: string;
}

export function Pulsar(props: PulsarProps) {
    return (
        <svg viewBox="0 0 76 76" height="76" width="76" fill="none" className={classnames('Pulsar', props.color)}>
            <circle cx="38" cy="38" r="34" strokeWidth="6" fill="none" />
        </svg>
    );
}
