import {Links, Link} from '../../components/Footer/Footer';
import { CLOUDS } from '../../Constants';

export const AvrIotBuyLink         = 'https://www.microchipdirect.com/dev-tools/EV15R70A?allDevTools=true';
export const AvrIotLatestAwsFwLink = 'https://github.com/microchip-pic-avr-solutions/avr-iot-aws-sensor-node-mplab/releases/latest';
export const AvrIotLatestGcpFwLink = 'https://github.com/microchip-pic-avr-solutions/avr-iot-google-sensor-node-mplab/releases/latest';

export function AvrIotFooterLinks(): Links[] {
    return [
        {
            header: 'Rapid Development Tools',
            links: [
                {
                    text: 'Atmel START',
                    url:  'https://start.atmel.com/'
                },{
                    text: 'MPLAB Code Configurator',
                    url:  'https://microchip.com/mcc'
                }
            ]
            }, {
            header: 'Other IoT Solutions & Architecture',
            links: [
                {
                    text: 'All Microchip IoT Solutions',
                    url:  'https://www.microchip.com/en-us/solutions/internet-of-things'
                }
            ]
        }
    ];
}

export function AvrIotDocumentationLinks(cloud: CLOUDS): Link[] {
    let awsIotDeveloperGuideText : string | null = 'Microchip IoT Developer Guides for AWS';
    let awsAppUserGuideText      : string | null = 'Application User Guide - AWS';
    let awsSourceCodeUrlText     : string | null = 'Embedded Source Code - AWS';
    let gcpAppUserGuideText      : string | null = 'Application User Guide - Google Cloud';
    let gcpSourceCodeUrlText     : string | null = 'Embedded Source Code - Google Cloud';
    
    const awsIotDeveloperGuide   : string | null = 'https://github.com/microchip-pic-avr-solutions/microchip-iot-developer-guides-for-aws';
    const awsAppUserGuide        : string | null = 'https://www.microchip.com/DS50002998';
    const awsSourceCodeUrl       : string | null = 'https://github.com/microchip-pic-avr-solutions/avr-iot-aws-sensor-node-mplab';
    const gcpAppUserGuide        : string | null = 'https://www.microchip.com/DS50002809';
    const gcpSourceCodeUrl       : string | null = 'https://github.com/microchip-pic-avr-solutions/avr-iot-google-sensor-node-mplab';

    switch (cloud) {
        case CLOUDS.aws:
            awsAppUserGuideText  = 'Application User Guide';
            awsSourceCodeUrlText = 'Embedded Source Code';
            gcpSourceCodeUrlText = null;
            gcpAppUserGuideText  = null;
            break;
    
        case CLOUDS.gcp:
            awsIotDeveloperGuideText = null;
            gcpAppUserGuideText  = 'Application User Guide';
            gcpSourceCodeUrlText = 'Embedded Source Code';
            awsSourceCodeUrlText = null;
            awsAppUserGuideText  = null;
            break;
    }

    return [
        {
            text: awsIotDeveloperGuideText,
            url: awsIotDeveloperGuide
        },{
            text: awsAppUserGuideText,
            url:  awsAppUserGuide
        },{
            text: gcpAppUserGuideText,
            url:  gcpAppUserGuide
        },{
            text: 'Hardware Users Guide',
            url:  'https://www.microchip.com/DS50002805'
        },{
            text: 'Pin Diagram',
            url:  'https://www.microchip.com/DS30010192'
        },{
            text: awsSourceCodeUrlText,
            url:  awsSourceCodeUrl
        },{
            text: gcpSourceCodeUrlText,
            url:  gcpSourceCodeUrl
        }
    ].filter((l) => l.text);
}

