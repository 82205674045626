type WifiConnectedTileProps = {
    onRetryClick: Function
};

export default function WifiConnectedTile(props: WifiConnectedTileProps) {
    return (
        <div className="tile wifisetuptile">
            <p>Download (or drag and drop) your <samp>wifi.config</samp> file onto your device. If Wi-Fi® 
                connection is successful, you should see your blue LED light up on the device.</p>
            <div className="wireless-config-connected-video">
                <video loop autoPlay id="connectedVideo">
                    <source src="https://storage.googleapis.com/avr-iot-media/avr-iot_wifi_connected.mp4"/>
                </video>
            </div>
            <p>Click Retry to enter your Wifi information and generate a new config file.</p>
            <div className="wifi-connected-buttons">
                <button id="" type="button" className="btn gray wifi-connected-btn" onClick={() => props.onRetryClick()} >Retry</button>
            </div>
        </div>
    );
}
