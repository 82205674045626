import { useState } from 'react';
import { SimpleSlider } from '../../components/Controls/SimpleSliderControl';
import { LedToggleButton } from '../../components/LedToggleButton/LedToggleButton';
import Visualizer from '../../components/Visualizer/Visualizer';
import { CLOUDS } from '../../Constants';
import './ArduinoDashboard.scss';

type ArduinoDashboardProps = {
    thingName: string;
    data: any;
    cloud: CLOUDS;
    isStreaming: boolean;
    onSubmit: (data: any) => void;
}

export function ArduinoDashboard(props: ArduinoDashboardProps) {
    const [duration, setDuration] = useState<number>(1);
    const [freq, setFreq] = useState<number>(1000);
    const [verboseLogClicked, setVerboseLogClicked] = useState<boolean>(false);

    function handleStartStreaming() {
        props.onSubmit({
            cmd: 'stream',
            opts: { duration: duration * 60, freq }
        });
    }

    function handleVerboseLog() {
        setVerboseLogClicked(true);
        props.onSubmit({
            cmd: 'verbose_logs',
            opts: {}
        });
    }

    const handleLedClick = (ledName: string, state: number) => {
        props.onSubmit({
            cmd: 'set_led',
            opts: {
                'led': ledName,
                'state': state
            }
        });
    };

    const visualizer = props.isStreaming ?
        <Visualizer thingName={props.thingName} data={props.data} cloud={props.cloud} />
        : <section className='content-wrapper'><p>The board is not connected.</p></section>;

    return (
        <section className='Dashboard arduino'>
            <section className='content-wrapper'>
                <h2>Arduino Demo Sandbox</h2>
            </section>

            <div className="content">
                {visualizer}
            </div>

            <section className='content-wrapper'>
                <h2>Interact with the Board</h2>
                <h3>Stream Sensor Data</h3>
                <p>
                    The board has two sensors, one temperature sensor and one RGB sensor. The sandbox allows to stream this data to the sandbox; use the below control panel to start the streaming. The data is streamed over the cellular network, be careful with the amount of data you are sending.
                </p>
                <p>
                    <b>Duration</b> sets for how long to send data, <b>frequency</b> sets how often to send data.
                </p>
                <div className='slider'>
                    <div className='label'>Duration: {duration} minutes</div>
                    <SimpleSlider id={1} value={duration} min={1} max={10} step={1} onValueChange={(id: number, value: number) => { setDuration(value); }} />
                </div>
                <div className='slider'>
                    <div className='label'>Frequency: {freq} milliseconds</div>
                    <SimpleSlider id={2} value={freq} min={500} max={10000} step={5} onValueChange={(id: number, value: number) => { setFreq(value); }} />
                </div>

                <div className='stream-buttons'>
                    <button className="btn red" onClick={handleStartStreaming}>Start Sensor Data Streaming</button>
                    <button style={{ marginLeft: '10px' }} className="btn red-inverted" onClick={handleVerboseLog}>Set Logging to Verbose (Serial Output)</button>
                </div>
                {(() => {
                    if (verboseLogClicked) {
                        return (
                            <div style={{ justifyContent: 'end', display: 'flex', marginTop: '10px' }}>
                                <b>Starting verbose logging. See the serial output from the terminal at the bottom of the page.</b>
                            </div>
                        );
                    }
                })()}
            </section>
            <section className='content-wrapper'>
                <h3>Control Leds</h3>
                <p>Click on the buttons below to toggle the LEDs on the board.</p>
                <b>Tip: Be careful about sending too many messages, as this can overwhelm the receive buffer on the sandbox.</b>
                <div style={{ textAlign: 'center' }}>
                    <LedToggleButton ledName='USER' clickHander={handleLedClick} />
                    <LedToggleButton ledName='ERROR' clickHander={handleLedClick} />
                </div>
            </section>
        </section>
    );
}