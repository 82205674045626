import { Link } from 'react-router-dom';
import { CLOUDS } from '../../Constants';
import awsLogo from '../../images/aws-logo.svg';
import gcpLogo3 from '../../images/google-cloud-logo3.png';
import azureLogo from '../../images/azure-logo.svg';
import './KitBox.scss';

export enum ConnectionType {
    none,
    wifi,
    ltem
}

type KitBoxPropsType = {
    
    image: JSX.Element;
    kitnames: string[];
    link: string;
    connection: ConnectionType;
    clouds: CLOUDS[];
}

export function KitBox(props: KitBoxPropsType) {
    let title = <h4>{
            props.kitnames
            .map((n) => <span key={n}>{n}</span>)
            .reduce((acc, x) => acc === null ? x : <>{acc}{x}</>)
        }</h4>;

    let connection = <></>;
    switch (props.connection) {
        case ConnectionType.wifi:
            connection = <div className="connection red">WiFi</div>;
            break;
        case ConnectionType.ltem:
            connection = <div className="connection blue">LTE-M</div>;
            break;
    }

    function CloudBadge(cloud: CLOUDS, n: number) {
        switch (cloud) {
            case CLOUDS.aws:
                return <img key={n} className="aws" src={awsLogo} alt="AWS" title="Amazon Web Services" />;
            case CLOUDS.gcp:
                return <img key={n} className="gcp" src={gcpLogo3} alt="Google Cloud" title="Google Cloud" />;
            case CLOUDS.azure:
                return <img key={n} className="azure" src={azureLogo} alt="Azure" title="Azure" />;
            default:
                return <></>;
        }
    }

    function FlexLink(props: {url: string, children?: React.ReactNode}) {
        const url = props.url;

        if (url.startsWith('https://') || url.startsWith('http://')) {
            return <a href={url}>{props.children}</a>;
        } else {
            return <Link to={url}>{props.children}</Link>;
        }
    }

    return (
        <div className="KitBox">
            <FlexLink url={props.link}>
                <div className="kitimage">
                    {props.image}
                </div>
                <div className="description">
                    {title}
                    <hr />
                    <div className="properties">
                        {connection}
                        <div>
                            {props.clouds.map( (c, i) => CloudBadge(c, i) )}
                        </div>
                    </div>
                </div>
            </FlexLink>
        </div>
    );
}
