import SimpleExpander from '../../components/Expanders/SimpleExpander';
import { BOARDS, CLOUDS } from '../../Constants';
import { OutboundLink } from '../Links/Links';
import './Welcome.scss';

const haveABoard = (boardNameAorAn: string, boardName: string, board: BOARDS, buyLink: string) => {
    return (
        <div className="inset">
            <h3>If You Already Have {boardNameAorAn} {boardName} Board:</h3>
            <ol className="blue-bullets welcome-info-text">
                <li><div>Connect the {boardName} board to your laptop using a standard micro-USB cable</div></li>
                <li><div>Open the “CURIOSITY” drive</div></li>
                <li><div>Click on “CLICK-ME.HTM”</div></li>
            </ol>
            <div>
                <h4>Don’t have {boardNameAorAn} {boardName} Board?</h4>
                <div className="btn red">
                    <OutboundLink eventLabel={'Buy: ' + board} to={buyLink}>Buy Now</OutboundLink>
                </div>
            </div>
        </div>
    );
};

export const haveABoardPic32Mzw1 = (boardNameAorAn: string, boardName: string) => {
    return (
        <div className="inset">
            <h3>If You Already Have {boardNameAorAn} {boardName} Board:</h3>
            <ol className="blue-bullets welcome-info-text">
                <li><div>Connect the {boardName} board to your laptop using a standard micro-USB cable</div></li>
                <li><div>Open the MSD drive</div></li>
                <li><div>Click on clickme.html</div></li>
            </ol>
            <div>
                <h4>Don’t have {boardNameAorAn} {boardName} Board?</h4>
                <p>Choose one from the list below</p>
            </div>
        </div>
    );
};

const upgradeFirmware = (fwLink: string) => {
    return (
        <div className="inset">
            <h3>Update to the Latest Firmware</h3>
            <p>Before you start, it is recommended to update the kit to the latest firmware.</p>
            <p>Download the firmware and simply drag and drop the .hex file onto your CURIOSITY USB drive.</p>
            <div className="btn red">
                <OutboundLink eventLabel={'Get latest fw: ' + fwLink} to={fwLink}>Get the Latest Firmware</OutboundLink>
            </div>
        </div>
    );
};

const googleCloudCollapsible = () => {
    let gcpUrl = 'https://cloud.google.com/iot-core/';

    return (
        <SimpleExpander key="gcpCollapse" collapsedTitle="Google Cloud IoT Core" expandedTitle="Google Cloud IoT Core" class="white">
            Google IoT Core Cloud is a fully managed service that allows you to easily and securely connect,
            manage, and ingest data from millions of globally dispersed
            devices. <a href={gcpUrl} target="_blank" rel="noreferrer">Cloud IoT Core</a> in
            combination with other services on the Google Cloud IoT platform, provides a complete solution for collecting,
            processing, analyzing, and visualizing IoT data in real time to support improved operational efficiency.
        </SimpleExpander>
    );
};

const awsCloudCollapsible = () => {
    let awsUrl = 'https://www.microchip.com/design-centers/internet-of-things/amazon-web-services';
    return (
        <SimpleExpander key="awsCollapse" collapsedTitle="Amazon Web Services" expandedTitle="Amazon Web Services" class="white">
            To enable the creation of smart, connected and secure designs, Microchip has expanded its collaboration with
            Amazon Web Services(AWS) to support cloud - connected embedded systems from the node to the cloud.
            Supporting Amazon Greengrass, Amazon FreeRTOS and AWS Internet of Things(IoT), Microchip
            provides <a href={awsUrl} target="_blank" rel="noreferrer">all the components</a>,
            tools, software and support needed to rapidly develop secure cloud - connected systems.
        </SimpleExpander>
    );
};

type WelcomeProps = {
    cloud: CLOUDS;
    title: string;
    boardName: string;
    boardNameAorAn: string;
    board: BOARDS;
    familyDropdown: any;
    buyLink: string;
    fwLink: string;
    showUpgrade: boolean;
    progressBar: JSX.Element;
    haveABoard?: JSX.Element;
};

export default function Welcome(props: WelcomeProps) {
    let createLink = (name: string, url: string) => {
        return ( <a href={url} target="_blank" rel="noopener noreferrer">{name}</a> );
    };

    let cloudProvider = ( null );
    let cloudCollapsible = ( null );
    switch (props.cloud) {
        case CLOUDS.aws:
            cloudProvider = createLink('AWS', 'https://www.microchip.com/design-centers/internet-of-things/amazon-web-services');
            cloudCollapsible = awsCloudCollapsible();
            break;
            
            case CLOUDS.gcp:
                cloudProvider = createLink('Google', 'https://www.microchip.com/design-centers/internet-of-things/google-cloud-iot');
                cloudCollapsible = googleCloudCollapsible();
            break;

        default:
            cloudProvider = 'the top cloud providers';
            cloudCollapsible = null;
    }

    const haveaboard = props.haveABoard !==undefined ? props.haveABoard : haveABoard(props.boardNameAorAn, props.boardName, props.board, props.buyLink);
    const inset = props.showUpgrade ? upgradeFirmware(props.fwLink) : haveaboard;


    return (
        <div className="welcome">
            <div className="content-wrapper">
                <div className="content">
                    <div className="left">
                        <h2>{props.title}</h2>
                        <h5>Get your things on the Internet in 30 Seconds Flat with the {props.boardName} boards!</h5>
                        <p className="welcome-info-text">There is no faster way to get your sensors and actuators connected. We have collaborated
                            with { cloudProvider } to offer you the simplest and most effective way to give your Things the power of the Internet.
                        </p>
                        {props.familyDropdown}
                        {cloudCollapsible}
                    </div>
                    {inset}
                </div>
                {props.progressBar}
            </div>
        </div>
    );
}
