import './WhatsNext.scss';
import { ExpandIcon } from '../Expanders/Icon';

import 'rc-collapse/assets/index.css';
import '../Expanders/rc-collapse.scss';
import Collapse from 'rc-collapse';
import { BOARDS } from '../../Constants';
import { Event } from '../Links/Links';
const Panel = Collapse.Panel;

function Icon(props: object) {
    let {isActive} = props as {isActive: boolean};
    return ExpandIcon(isActive, '#4285F4', 2, 'transparent');
}


type WhatsNextProps = {
    tutorials: {title: string, content: string}[],
    board: BOARDS
};

export default function WhatsNext(props: WhatsNextProps) {
    return (
        <div className="WhatsNext">
            <div className="content-wrapper">
                <h3>What's Next</h3>
                <p>Explore your development board by adding sensors and actuators. Visualize more sensor data,
                    add cloud-controlled actuators and start building your custom design.</p>

                    <Collapse accordion={true}
                        className="rounded"
                        defaultActiveKey="0"
                        expandIcon={Icon}
                        onChange={(key) => {
                            if (key !== undefined) {
                                Event({category: props.board +  ' tutorials', action: 'Expanded', value: Number.parseInt(key.toString())});
                            }
                        }}
                    >
                        {props.tutorials.map((t, index) =>
                            <Panel key={index+1}
                                header={t.title} 
                                headerClass="rounded-header"
                                className="rounded"
                            >
                                {t.content}
                            </Panel>
                        )}
                    </Collapse>
            </div>
        </div>
    );
}
