import { MouseEvent, useState } from 'react';
import ToggleControl from './ToggleControl';
import './SimpleControlPane.scss';

export type SimpleControlPaneData = {
    toggle: number
}

type SimpleControlPaneProps = {
    onSubmit: (data: SimpleControlPaneData) => void
};


export default function SimpleControlPane(props: SimpleControlPaneProps) {
    const [toggle, setToggle] = useState(false);

    function toggleHandler(id: number, value: boolean) {
        setToggle(value);
    }

    function submitHandler(event: MouseEvent) {
        event.preventDefault();
        props.onSubmit({ toggle: toggle ? 1 : 0 });
    }
    
    return (
        <div className="content-wrapper">
            <div className="tile controlstile">
                <h3>Control Your Device</h3>
                <ToggleControl id={1} label='Toggle' checked={toggle} onClick={toggleHandler} />
                <button className="btn red" onClick={ submitHandler }>Send to device</button>
            </div>
        </div>
    );
}
