import React from 'react';
import './WirelessNetworkConnection.scss';
import WifiSetupTile from './WifiSetupTile';
import WifiConnectedTile from './WifiConnectedTile';

type WifiConnectionProps = {
    hasWPA3?: boolean;
    showAlert?: boolean;
};

type WifiConnectionState = {
    showSetup: boolean;
    showConnected: boolean;
    showAlert: boolean;
};

export default class WirelessNetworkConnection extends React.Component<WifiConnectionProps, WifiConnectionState> {
    hasWPA3?: boolean;

    constructor(props: WifiConnectionProps) {
        super(props);

        this.hasWPA3 = props.hasWPA3;

        let showAlert = props.showAlert === undefined ? true : props.showAlert;

        this.state = {
            showSetup: true,
            showConnected: false,
            showAlert
        };
    }

    handleDownloaded() {
        this.setState({ showSetup: false, showConnected: true });
    }
    
    handleRetry() {
        this.setState({ showSetup: true, showConnected: false });
    }

    render() {
        let alert;
        if (this.state.showAlert === undefined) {
            alert = (
                <header className="alert">
                    <p className="alert-text">We couldn't retrieve recent data from your device. To connect to a network
                        automatically, ensure the device is plugged in.</p>
                </header>
            );
        } else {
            alert = <></>;
        }

        return (
            <section className="wificonfig">
                {alert}
                <div className="content-wrapper">
                    <div className="tile mobile-warning">
                        <h4>Mobile users</h4>
                        <p>To configure your device's Wi-Fi®, visit this site from a laptop or PC. Mobile configuration is not
                            currently supported.</p>
                    </div>

                    <h3>Wireless Network Connection</h3>
                    <div className="wificonfig-wrapper">

                        { this.state.showSetup     ? <WifiSetupTile onDownloadClick={ () => this.handleDownloaded() } hasWPA3 = {this.hasWPA3} /> : null }
                        { this.state.showConnected ? <WifiConnectedTile onRetryClick={ () => this.handleRetry() } /> : null }
                        
                        {/* Right side setup video */}
                        <div>
                            <h4>Setup Instructions</h4>
                            <video id="setup-video" autoPlay muted loop>
                                <source src="https://storage.googleapis.com/avr-iot-media/setup.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
