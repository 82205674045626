import ReactGA from 'react-ga';

type LinkProps = {
    eventLabel:	string,
    to:         string,
    target?:	string
    children: React.ReactNode;
};

export function OutboundLink({eventLabel, to, target = '_blank', children}: LinkProps) {
    let ga: string = (window as any).ga_id;
    
    if (ga.length > 8) {
        return <ReactGA.OutboundLink
        eventLabel={eventLabel}
        to={to}
        target={target}
        >{children}</ReactGA.OutboundLink>;
    } else {
        return <a href={to} target={target}>{children}</a>;
    }
}

type EventArgs = {
    category: string,
    action: string,
    label?: string,
    value?: number
}

export function Event(args: EventArgs) {
    let ga: string = (window as any).ga_id;
    
    if (ga.length > 8) {
        ReactGA.event(args);
    }
}
