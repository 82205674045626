import React from 'react';
import { DeleteIcon } from './DeleteIcon';
import './DeleteButton.scss';

type DeleteButtonProps = {
    onClick: () => void;
};

export default function DeleteButton(props: DeleteButtonProps) {
    return (
        <button className="delete-button" onClick={() => props.onClick() }><DeleteIcon/></button>
    );
}
