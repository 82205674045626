import React                                     from 'react';
import { Link }                                  from 'react-router-dom';
import { OutboundLink }                          from 'react-ga';
import TopNav                                    from '../../components/TopNav/TopNav';
import Welcome                                   from '../../components/Welcome/Welcome';
import Visualizer                                from '../../components/Visualizer/Visualizer';
import WifiConfig                                from '../../components/Wifi/WirelessNetworkConnection';
import { PicAvrFamilyCollapsible }               from '../../components/McuFamilyCollapsibles/McuFamilyCollapsibles';
import { ProgressBar, ProgressBarProps, STAGES } from '../../components/ProgressBar/ProgressBar';
import { Footer, Link as DocLink }               from '../../components/Footer/Footer';
import { Provision }                             from '../../components/Provision/Provision';
import { ProvisionDescriptions }                 from '../../components/Provision/PicAvrProvisionDescriptions';
import { BOARDS, CLOUDS }                        from '../../Constants';
import { DeviceManager, deviceManagerFactory }   from '../../utils/DeviceManagerFactory';
import {
    PicIotBuyLink,
    PicIotLatestAwsFwLink,
    PicIotLatestGcpFwLink,
    PicIotFooterLinks, 
    PicIotDocumentationLinks}                    from './PicIotLinks';
import piciotvideo                               from '../../images/PicIotWX.mp4';
import './PicIot.scss';
import '../../Landingpage.scss';
import SimpleControlPane from '../../components/Controls/SimpleControlPane';

type PicIotState = {
    thingName: string,
    cloud: CLOUDS;
    streamTimeout?: NodeJS.Timeout,
    mode: string,
    data: any[],
    toggle: boolean
}

export default class PicIotPage extends React.Component<{}, PicIotState>{
    deviceManager: DeviceManager|null;
    fwLink: string;

    constructor(props: any) {
        super(props);

        let search = new URLSearchParams(props.location.search);
        const id = search.get('id');

        let cloud;
        switch (search.get('cloud')?.toLowerCase()) {
            case CLOUDS.gcp:
                cloud = CLOUDS.gcp;
                this.fwLink = PicIotLatestGcpFwLink;
                break;
                
            case CLOUDS.aws:
                cloud = CLOUDS.aws;
                this.fwLink = PicIotLatestAwsFwLink;
                break;

            default:
                cloud = CLOUDS.none;
                this.fwLink = '';
                break;
        }

        const thingName = id ? id.toLowerCase() : '';

        this.deviceManager = deviceManagerFactory(cloud, BOARDS.picIot);
        this.deviceManager?.setDevice(thingName);

        this.state = {
            thingName: thingName,
            cloud,
            streamTimeout: undefined,
            mode: thingName !== '' && cloud !== CLOUDS.none ? 'init' : 'unknown',
            data: [],
            toggle: false
        };

        this.controlsSubmitHandler = this.controlsSubmitHandler.bind(this);
    }

    componentDidMount() {
        if (this.state.mode !== 'unknown') {
            const timeout = setTimeout(() => {
                this.setState({ mode: 'config' });
            }, 3000);
            this.setState({ streamTimeout: timeout });
            
            this.deviceManager?.setOnMessage((data) => {
                // listen to data sent from the websocket server
                data = this.state.data.slice(-20).concat(data);
                
                if (this.state.streamTimeout) {
                    clearTimeout(this.state.streamTimeout);
                }
                const timeout = setTimeout(() => {
                    this.setState({ mode: 'config' });
                }, 5000);
                
                this.setState({
                    streamTimeout: timeout,
                    mode: 'visualizer',
                    data: data
                });
            });
            
            this.deviceManager?.connect();
        }
    }

    controlsSubmitHandler(data: any) {
        this.deviceManager?.sendDeviceConfig({ toggle: data.toggle } );
    }

    render() {
        const wifiConfig = this.state.mode === 'config'     ? <WifiConfig /> : null;
        const visualizer = this.state.mode === 'visualizer' ? <Visualizer thingName={this.state.thingName} data={this.state.data} cloud={this.state.cloud} /> : null;
        const whatsNext = this.state.cloud === CLOUDS.aws   ? <WhatsNext onSubmit={this.controlsSubmitHandler} /> : null;
        const showUpgrade = this.state.thingName !== '';
        const video = this.state.mode === 'unknown' ? <OverviewVideo /> : null;
        const provisionBox  = this.state.mode !== 'unknown' ? <ProvisionBox link="/pic-iot" /> : null;
        const docLinks = PicIotDocumentationLinks(this.state.cloud);
        const docsBox = this.state.mode !== 'unknown' ? <DocsBox links={docLinks} board={BOARDS.picIot} /> : null;
        const docsSection = this.state.mode === 'unknown' ? <DocsSection links={docLinks} board={BOARDS.picIot} /> : null;
        const provisionSection = this.state.mode === 'unknown' ? (
            <Provision 
                buttons={[
                    CLOUDS.aws,
                    CLOUDS.azure
                ]}
                descriptions={[
                    ProvisionDescriptions(BOARDS.picIot).aws,
                    ProvisionDescriptions(BOARDS.picIot).azure
                ]}
            />
        ) : null ;

        let pbProps: ProgressBarProps;
        switch (this.state.mode) {
            default:
            case 'unknown':
                pbProps = {
                    cloud: this.state.cloud,
                    stage: STAGES.board,
                    active: false,
                    flash: false
                };
                break;

            case 'init':
                pbProps = {
                    cloud: this.state.cloud,
                    stage: STAGES.wifi,
                    active: false,
                    flash: true
                };
                break;

            case 'config':
                pbProps = {
                    cloud: this.state.cloud,
                    stage: STAGES.wifi,
                    active: false,
                    flash: false
                };
                break;

            case 'visualizer':
                pbProps = {
                    cloud: this.state.cloud,
                    stage: STAGES.streaming,
                    active: true,
                    flash: false
                };
                break;
        }

        const progressBar = ProgressBar(pbProps);
        
        return (
            <div className="landingpage PicIot">
                <TopNav cloud={ this.state.cloud } />
                <Welcome title="PIC-IoT Development Boards" boardNameAorAn="a" boardName="PIC-IoT" board={BOARDS.picIot}
                    buyLink={PicIotBuyLink}
                    fwLink={this.fwLink}
                    cloud={this.state.cloud}
                    familyDropdown={PicAvrFamilyCollapsible()}
                    showUpgrade={showUpgrade}
                    progressBar={progressBar}
                />
                { wifiConfig }
                { visualizer }
                {provisionSection}
                <div className="content-wrapper boxes">
                    {docsBox}
                    {provisionBox}
                    {docsSection}
                    {video}
                </div>

                {whatsNext}                
                <Footer links={PicIotFooterLinks()} board={BOARDS.picIot}/>
            </div>
        );
    }
}

type ProvisionBoxProps = {
    link: string;
}

function ProvisionBox(props: ProvisionBoxProps) {
    return (
        <div className="provision">
            <div className="inset">
                <h3>Try other cloud solutions</h3>
                <p>The board can be provisioned for various cloud solutions.</p>
                <div className="btn red center">
                    <Link className="App-link" to={props.link}>See more</Link>
                </div>
            </div>
        </div>
    );
}

type DocsBoxProps = {
    links: DocLink[];
    board: BOARDS;
}

function DocsBox(props: DocsBoxProps) {
    let links = props.links.map((link) => { return {text: link.text as string, url: link.url as string}; });

    let list;
    if (links) {
        list = links.map((link) => (
            <li key={link?.text?.replace(' ', '')}>
                <OutboundLink to={link.url} eventLabel={'Docs link for' + props.board}>{link.text}</OutboundLink>
            </li>
        ));
    } else {
        list = <></>;
    }

    return (
        <div className="docs">
            <div className="inset">
                <h3>Have a look at the documentation</h3>
                <ul>
                    {list}
                </ul>
            </div>
        </div>
    );
}

function DocsSection(props: DocsBoxProps) {
    let links = props.links.map((link) => { return {text: link.text as string, url: link.url as string}; });

    let list;
    if (links) {
        list = links.map((link) => (
            <li key={link?.text?.replace(' ', '')}>
                <OutboundLink to={link.url} eventLabel={'Docs link for' + props.board}>{link.text}</OutboundLink>
            </li>
        ));
    } else {
        list = <></>;
    }

    return (
        <div className="docs-section">
            <h3>Have a look at the documentation</h3>
            <ul>
                {list}
            </ul>
        </div>
    );
}

function OverviewVideo() {
    return <div className="overview-video">
        <video id="deviceVideo" autoPlay muted loop>
            <source src={piciotvideo} type="video/mp4" />
        </video>
    </div>;
}

type WhatsNextProps = {
    onSubmit: (data: any) => void;
}

function WhatsNext(props: WhatsNextProps) {
    return (
        <div className="WhatsNext">
            <div className="content-wrapper">
                <h3>What's Next</h3>
                <p>Microchip IoT Developer Guides for AWS is a set of hands-on tutorials and
                    technical articles curated to help you get started with integrated IoT design. You can find them on GitHub.</p>
                <div className="btn red center">
                    <OutboundLink target="_blank" eventLabel={'Whats next link for ' + BOARDS.picIot}
                        to='https://github.com/microchip-pic-avr-solutions/microchip-iot-developer-guides-for-aws'>Read more</OutboundLink>
                </div>


                <div className='control-your-device'>
                    <h3>Control Your Device</h3>
                    Trigger an action by simply pressing the <b>Send to device</b> button.
                    <ul>
                        <li>When the toggle control is "on", the YELLOW LED will remain on for (2) Seconds.</li>
                        <li>When the toggle control is "off", the YELLOW LED will remain off for (2) Seconds.</li>
                        <li>Because Toggle manipulates the desired state, the state must be changed to observe the behavior.</li>
                    </ul>

                </div>

                <SimpleControlPane onSubmit={(data) => props.onSubmit(data)} />
            </div>
        </div>
    );
}