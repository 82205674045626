import classNames from 'classnames';
import { useState } from 'react';
import { CLOUDS } from '../../Constants';
import './Provision.scss';

import aws from '../../images/aws-logo.svg';
import gcp from '../../images/google-cloud-logo.svg';
import azure from '../../images/azure-logo.svg';

export type ProvisionProps = {
    buttons: CLOUDS[];
    descriptions: React.ReactElement[];
};

export function Provision(props: ProvisionProps) {
    const [cloud, setCloud] = useState<string>();

    let buttons = props.buttons.map((b) => <ProvisionButton cloud={b} selected={b === cloud} onClick={() => setCloud(b)} />);

    let i = props.buttons.findIndex((b) => b === cloud);

    let description = props.descriptions[i] || null ;

    return (
        <div className="Provision">
            <div className="content-wrapper">
                <h2>Get started</h2>
                <h3>Select the cloud provider you want to use</h3>
                <div className="select-cloud-provider">
                    {buttons}
                </div>
                {description}
            </div>
        </div>
    );
}

type ProvisionButtonProps = {
    cloud: CLOUDS;
    selected: boolean;
    onClick: () => void;
}

function ProvisionButton(props: ProvisionButtonProps) {
    let icon;
    let classname;
    let name;
    switch (props.cloud) {
        case CLOUDS.aws:
            icon = aws;
            classname = 'aws';
            name = '';
            break;
        case CLOUDS.gcp:
            icon = gcp;
            classname = 'gcp';
            name = '';
            break;
        case CLOUDS.azure:
            icon = azure;
            classname = 'azure';
            name = 'Azure';
            break;
    }

    return (
        <button key={classname} onClick={props.onClick}
                className={classNames('btn', 'cloud-provider', {selected: props.selected })}>
            <img className={classname} src={ icon } alt="logo"/>
            <span>{name}</span>
        </button>
    );
}

