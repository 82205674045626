import { ChangeEvent } from 'react';
import { EditText, onSaveProps } from 'react-edit-text';


import 'react-edit-text/dist/index.css';
import DeleteButton from './DeleteButton';
import './ToggleControl.scss';

type ToggleControlProps = {
    label: string;
    id: number;
    checked: boolean;
    onClick: (id: number, value: boolean) => void;
    onDelete?: (id: number) => void;
    onChangeLabel?: (id: number, label: string) => void;
};

export default function ToggleControl(props: ToggleControlProps) {
    const onDelete = () => {
        if (props.onDelete) {
            props.onDelete(props.id);
        }
    };

    const handleSave = (saveProps: onSaveProps) => {
        let { value } = saveProps;
        
        if (props.onChangeLabel) {
            props.onChangeLabel(props.id, value);
        }
    };

    // let deleteButton = props.onDelete ? {db} : null;

    return (
        <div className="toggle">
            <EditText
                className="label"
                name={props.label}
                defaultValue={props.label}
                placeholder='Enter a name'
                onSave={handleSave}
                // style={{width: '200px'}}
                inline
            />
            <label>
                <input type="checkbox" checked={props.checked}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => props.onClick(props.id, event.target.checked)}/>
                <span/>
            </label>
            <DeleteButton onClick={onDelete} />
        </div>
    );
}
