import Sockette from 'sockette';
import { AES } from 'crypto-js';
import { DeviceManager } from './DeviceManagerFactory';

export class AWSDeviceManager implements DeviceManager {
    ws: Sockette | null = null;
    awsWebsocketUrl: string;
    awsConnectionSecret: string;
    env: any;
    offset: Number;
    device: string;
    onMessage: ((data: any) => any) | undefined = undefined;

    constructor(awsWebsocketUrl: string, awsConnectionSecret: string, offset: Number, env: any) {
        this.awsWebsocketUrl = awsWebsocketUrl;
        this.awsConnectionSecret = awsConnectionSecret;
        this.env = env;
        this.offset = offset || 0;
        this.device = '';
    }

    setDevice = (uid: string) => { this.device = uid; };

    setOnMessage = (onMessage: any) => { this.onMessage = onMessage; };

    connect = () => {
        const parameters = JSON.stringify({ thingName: this.device });

        const encrypted = AES.encrypt(parameters, this.awsConnectionSecret).toString();

        let actions = {};
        // if (this.env === 'development') {
        //     actions = {
        //         onopen: (e: string) => console.log('Connected:', e),
        //         onreconnect: (e: string) => console.log('Reconnecting...', e),
        //         onmaximum: (e: string) => console.log('Stop Attempting!', e),
        //         onclose: (e: string) => console.log('Closed!', e),
        //         onerror: (e: string) => console.log('Error:', e)
        //     };
        // }

        this.ws = new Sockette(
            `${this.awsWebsocketUrl}/dev?t=${encrypted}`,
            {
                timeout: 5e3,
                maxAttempts: 1,
                onmessage: (evt) => {
                    if (this.onMessage) {
                        this.onMessage(JSON.parse(evt.data));
                    }
                },
                ...actions
            }
        );
    };

    disconnect = () => {
        this.ws?.close();
    };

    sendDeviceConfig = (msg: any) => {
        const data = {
            action: 'sendDeviceMessage',
            thingName: this.device,
            data: msg
        };
        this.ws?.send(JSON.stringify(data));
    };
}
