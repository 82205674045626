import {Links} from '../../components/Footer/Footer';

export const Pic32mzw1BuyLink = '';
export const Pic32mzw1LatestFWLink = 'https://github.com/MicrochipTech/PIC32MZW1_Curiosity_OOB/releases/latest';
export const Wfi32IotLatestFWLink  = 'https://github.com/MicrochipTech/WFI32-IoT/releases/latest';

export const Pic32mzw1FooterLinks: Links[] = [
    {
        header: 'Documentation',
        links: [
            {
                text: 'Curiosity Board Product Page',
                url:  'https://www.microchip.com/developmenttools/ProductDetails/PartNO/EV12F11A'
            },{
                text: 'WFI32-IoT Board Product Page',
                url:  'https://www.microchip.com/developmenttools/ProductDetails/PartNO/EV36W50A'
            },{
                text: 'Curiosity Embedded Source Code',
                url:  'https://github.com/MicrochipTech/PIC32MZW1_Curiosity_OOB'
            },{
                text: 'WFI32-IoT Embedded Source Code',
                url:  'https://github.com/MicrochipTech/WFI32-IoT'
            }
        ]
     }, {
        header: 'Rapid Development Tools',
        links: [
            {
                text: 'MPLAB Code Configurator',
                url:  'https://microchip.com/mcc'
            },{
                text: 'MPLAB XPRESS',
                url:  'http://www.microchip.com/xpress'
            }, {
                text: 'MPLAB Harmony v3',
                url:  'https://www.microchip.com/en-us/development-tools-tools-and-software/embedded-software-center/mplab-harmony-v3'
            }
        ]
    }, {
        header: 'Other IoT Solutions & Architecture',
        links: [
            {
                text: 'All Microchip IoT Solutions',
                url:  'https://www.microchip.com/en-us/solutions/internet-of-things'
            }
        ]
    }
];

