export function ExpandIcon(isActive: boolean, stroke: string, strokeWidth: number, fill: string) {
    return (
        <i style={{ marginRight: '.5rem', width: '22px', height: '22px',
                textAlign: 'center', verticalAlign: 'center' }}>
            <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"
                style={{
                    verticalAlign: '-.125em',
                    transition: 'transform .2s',
                    transform: `rotate(${isActive ? -180 : 0}deg)`,
                }}>
                <g fill="none" fillRule="evenodd">
                    <circle fill={fill} cx="8" cy="8" r="8"/>
                    <path d="M12 6l-3.997 4L4 6"
                        stroke={stroke}
                        strokeWidth={strokeWidth}
                        strokeLinecap="round"
                        strokeLinejoin="round" />
                </g>
            </svg>
        </i>
    );
}
