import classnames from 'classnames';
import { GearIcon } from './GearIcon';
import './SettingsButton.scss';

type SettingsButtonProps = {
    show: Boolean;
    onClick: () => void;
};

export default function SettingsButton(props: SettingsButtonProps) {
    return (
        <button className={classnames('settings-button', {checked: props.show})}
                onClick={() => props.onClick() }>
            <GearIcon/>
        </button>
    );
}
