export const MAX_DATA_POINTS = 20; // max number of data points to retrieve from firebase (or aws) and graph

export enum CLOUDS {
  gcp = 'gcp',
  aws = 'aws',
  azure = 'azure',
  none = 'none'
};

enum BOARD_FAMILIES {
  avrIot    = 'avr-iot',
  picIot    = 'pic-iot',
  samIot    = 'sam-iot',
  pic32mzw1 = 'pic32mzw1'
};

export enum BOARDS {
  avrIot       = 'avr-iot',
  picIot       = 'pic-iot',
  samIot       = 'sam-iot',
  pic32mzw1    = 'pic32mzw1',
  cellularMini = 'avr-iot-cellular-mini',
  none      = 'none'
};

export const BOARD_FAMILIES_BOARDS = {
  'avr-iot' : [ BOARDS.avrIot, BOARDS.cellularMini ],
  'pic-iot' : [ BOARDS.picIot, BOARD_FAMILIES.pic32mzw1 ],
  'sam-iot' : []
};
