import { ChangeEvent } from 'react';
import './SimpleSliderControl.scss';

type SimpleSliderProps = {
    id: number;
    min: number;
    max: number;
    step: number;
    value: number;
    onValueChange: (id: number, value: number) => void;
};

export function SimpleSlider(props: SimpleSliderProps) {
    const handleSliderValChange = (evt: ChangeEvent<HTMLInputElement>) => {
        let value = Number.parseInt(evt.target.value);
        props.onValueChange(props.id, value);
    };

    return (
        <input className="SimpleSlider input-slider" type="range" value={props.value.toString()} min={props.min.toString()} max={props.max.toString()} step={props.step.toString()} onChange={handleSliderValChange} />
    );
}
