import React                                     from 'react';
import { Link }                                  from 'react-router-dom';
import { OutboundLink }                          from 'react-ga';
import TopNav                                    from '../../components/TopNav/TopNav';
import Welcome                                   from '../../components/Welcome/Welcome';
import Visualizer                                from '../../components/Visualizer/Visualizer';
import WifiConfig                                from '../../components/Wifi/WirelessNetworkConnection';
import WhatsNext                                 from '../../components/WhatsNext/WhatsNext';
import { PicAvrFamilyCollapsible }               from '../../components/McuFamilyCollapsibles/McuFamilyCollapsibles';
import { ProgressBar, ProgressBarProps, STAGES } from '../../components/ProgressBar/ProgressBar';
import { Footer, Link as DocLink}                 from '../../components/Footer/Footer';
import { ProvisionDescriptions }                 from '../../components/Provision/PicAvrProvisionDescriptions';
import { BOARDS, CLOUDS }                        from '../../Constants';
import { DeviceManager, deviceManagerFactory }   from '../../utils/DeviceManagerFactory';
import AvrIotTutorials                           from './AvrIotTutorials';
import { 
    AvrIotBuyLink,
    AvrIotLatestAwsFwLink, 
    AvrIotLatestGcpFwLink,
    AvrIotFooterLinks,
    AvrIotDocumentationLinks }                   from './AvrIotLinks';
import avriotvideo                               from '../../images/AvrIotWX.m4v';
import './AvrIot.scss';
import '../../Landingpage.scss';

type AvrIotState = {
    thingName: string,
    cloud: CLOUDS;
    streamTimeout?: NodeJS.Timeout,
    mode: string,
    data: any[],
    toggle: boolean,
    fwLink: string;
};

export default class AvrIotPage extends React.Component<{}, AvrIotState>{
    deviceManager: DeviceManager | null = null;

    constructor(props: any) {
        super(props);

        const search = new URLSearchParams(props.location.search);
        const id = search.get('id');
        const cloud = CLOUDS.aws;

        this.state = this.init(id, cloud ? cloud : '');
        this.deviceManager = deviceManagerFactory(this.state.cloud, BOARDS.avrIot);
        this.deviceManager?.setDevice(this.state.thingName);
    }

    init(id: string | null, cloudName: string): AvrIotState {
        let fwLink: string;
        let cloud: CLOUDS;
        switch (cloudName) {
            case CLOUDS.gcp:
                cloud = CLOUDS.gcp;
                fwLink = AvrIotLatestGcpFwLink;
                break;
                
            case CLOUDS.aws:
                cloud = CLOUDS.aws;
                fwLink = AvrIotLatestAwsFwLink;
                break;

            default:
                cloud = CLOUDS.none;
                fwLink = '';
                break;
        }

        const thingName = id ? id.toLowerCase() : '';

        return {
            thingName: thingName,
            cloud,
            streamTimeout: undefined,
            mode: thingName !== '' && cloud !== CLOUDS.none ? 'init' : 'unknown',
            data: [],
            toggle: false,
            fwLink: fwLink
        };
    }

    componentDidMount() {
        if (this.state.mode !== 'unknown') {
            const timeout = setTimeout(() => {
                this.setState({ mode: 'config' });
            }, 3000);
            this.setState({ streamTimeout: timeout });
            
            this.deviceManager?.setOnMessage((data) => {
                // listen to data sent from the websocket server
                data = this.state.data.slice(-20).concat(data);
                
                if (this.state.streamTimeout) {
                    clearTimeout(this.state.streamTimeout);
                }
                const timeout = setTimeout(() => {
                    this.setState({ mode: 'config' });
                }, 5000);
                
                this.setState({
                    streamTimeout: timeout,
                    mode: 'visualizer',
                    data: data
                });
            });
            
            this.deviceManager?.connect();
        }
    }

    componentWillReceiveProps(nextProps: any) {
        const search = new URLSearchParams(nextProps.location.search);
        const id = search.get('id');
        const cloud = search.get('cloud')?.toLowerCase();

        let nextState = this.init(id, cloud ? cloud : '');
        
        if (nextState.mode === 'unknown') {
            this.deviceManager?.disconnect();
            if (this.state.streamTimeout) {
                clearTimeout(this.state.streamTimeout);
            }
        }

        this.deviceManager = deviceManagerFactory(nextState.cloud, BOARDS.avrIot);
        this.deviceManager?.setDevice(nextState.thingName);

        this.setState(nextState);

    }

    controlsSubmitHandler(data: any) {
        this.deviceManager?.sendDeviceConfig(data);
    }

    render() {
        const wifiConfig = this.state.mode === 'config'     ? <WifiConfig /> : null;
        const visualizer = this.state.mode === 'visualizer' ? <Visualizer thingName={this.state.thingName} data={this.state.data} cloud={this.state.cloud} /> : null;
        const showUpgrade = this.state.thingName !== '';
        const video = this.state.mode === 'unknown' ? <OverviewVideo /> : null;
        const provisionBox  = this.state.mode !== 'unknown' ? <ProvisionBox link="/avr-iot" /> : null;
        const docLinks = AvrIotDocumentationLinks(this.state.cloud);
        const docsBox = this.state.mode !== 'unknown' ? <DocsBox links={docLinks} board={BOARDS.avrIot} /> : null;
        const docsSection = this.state.mode === 'unknown' ? <DocsSection links={docLinks} board={BOARDS.avrIot} /> : null;
        const provisionSection = ProvisionDescriptions(BOARDS.avrIot).aws;

        let pbProps: ProgressBarProps;
        switch (this.state.mode) {
            default:
            case 'unknown':
                pbProps = {
                    cloud: this.state.cloud,
                    stage: STAGES.board,
                    active: false,
                    flash: false
                };
                break;

            case 'init':
                pbProps = {
                    cloud: this.state.cloud,
                    stage: STAGES.wifi,
                    active: false,
                    flash: true
                };
                break;

            case 'config':
                pbProps = {
                    cloud: this.state.cloud,
                    stage: STAGES.wifi,
                    active: false,
                    flash: false
                };
                break;

            case 'visualizer':
                pbProps = {
                    cloud: this.state.cloud,
                    stage: STAGES.streaming,
                    active: true,
                    flash: false
                };
                break;
        }

        const progressBar = ProgressBar(pbProps);
        
        return (
            <div className="landingpage">
                <TopNav cloud={ this.state.cloud } />
                <Welcome title="AVR-IoT Development Boards" boardNameAorAn="an" boardName="AVR-IoT" board={BOARDS.avrIot}
                    buyLink={AvrIotBuyLink}
                    fwLink={this.state.fwLink}
                    cloud={this.state.cloud}
                    familyDropdown={PicAvrFamilyCollapsible()}
                    showUpgrade={showUpgrade}
                    progressBar={progressBar}
                />
                { wifiConfig }
                { visualizer }
                <div className="content-wrapper">
                    {provisionSection}
                </div>
                <div className="content-wrapper boxes">
                    {docsBox}
                    {provisionBox}
                    {docsSection}
                    {video}
                </div>
                <WhatsNext tutorials={AvrIotTutorials((data) => this.controlsSubmitHandler(data), this.state.cloud)} board={BOARDS.avrIot}/>
                <Footer links={AvrIotFooterLinks()} board={BOARDS.avrIot}/>
            </div>
        );
    }
}


type ProvisionBoxProps = {
    link: string;
}

function ProvisionBox(props: ProvisionBoxProps) {
    return (
        <div className="provision">
            <div className="inset">
                <h3>Try other cloud solutions</h3>
                <p>The board can be provisioned for various cloud solutions.</p>
                <div className="btn red center">
                    <Link className="App-link" to={props.link}>See more</Link>
                </div>
            </div>
        </div>
    );
}

type DocsBoxProps = {
    links: DocLink[];
    board: BOARDS;
}

function DocsBox(props: DocsBoxProps) {
    let links = props.links.map((link) => { return {text: link.text as string, url: link.url as string}; });

    let list;
    if (links) {
        list = links.map((link) => (
            <li key={link?.text?.replace(' ', '')}>
                <OutboundLink to={link.url} eventLabel={'Docs link for' + props.board}>{link.text}</OutboundLink>
            </li>
        ));
    } else {
        list = <></>;
    }

    return (
        <div className="docs">
            <div className="inset">
                <h3>Have a look at the documentation</h3>
                <ul>
                    {list}
                </ul>
            </div>
        </div>
    );
}

function DocsSection(props: DocsBoxProps) {
    let links = props.links.map((link) => { return {text: link.text as string, url: link.url as string}; });

    let list;
    if (links) {
        list = links.map((link) => (
            <li key={link?.text?.replace(' ', '')}>
                <OutboundLink to={link.url} eventLabel={'Docs link for' + props.board}>{link.text}</OutboundLink>
            </li>
        ));
    } else {
        list = <></>;
    }

    return (
        <div className="docs-section">
            <h3>Have a look at the documentation</h3>
            <ul>
                {list}
            </ul>
        </div>
    );
}

function OverviewVideo() {
    return <div className="overview-video">
        <video id="deviceVideo" autoPlay muted loop>
            <source src={avriotvideo} type="video/mp4" />
        </video>
    </div>;
}
