import classnames from 'classnames';
import Lowlight from 'react-lowlight';
import c from 'highlight.js/lib/languages/c';
import './CodeView.scss';

Lowlight.registerLanguage('c', c);

interface CodeViewProps {
    markers?: number[];
    border?: boolean;
    children?: React.ReactNode;
}

export default function CodeView(props: CodeViewProps) {
    let border = props.border === undefined ? true : props.border;
    return (
        <div className={classnames('CodeView', {border: border})}>
            <Lowlight
                language="c"
                value={props.children}
                markers = {props.markers}
            />
        </div>
    );
}
