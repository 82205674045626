import * as React from 'react';
import { DomainTuple, VictoryAxis, VictoryChart, VictoryLine } from 'victory';
import { CLOUDS } from '../../Constants';

import './Visualizer.scss';

type VisualizerProps = {
    thingName: string,
    data: any,
    cloud: CLOUDS
};

export default class Visualizer extends React.Component<VisualizerProps> {
    getStrokeColor(cloud: CLOUDS) {
        switch (cloud) {
            case CLOUDS.aws:
                return 'orange';
            case CLOUDS.gcp:
                return 'rgb(66, 133, 244)';
            default:
                return 'black';
        }
    }

    public render() {
        let data = this.props.data;
        let last_data = data[data.length-1];
        let charts = [];
        for (let sensor in last_data) {
            if (sensor !== 'time') {
                let sensor_data = data.map((d: any) => d[sensor] !== undefined ? d[sensor] : null);
                charts.push(this.renderChart(sensor, sensor_data, this.getStrokeColor(this.props.cloud)));
            }
        }

        return (
            <main className="visualizer">
                <div className="widgets">
                    <section className='content-wrapper'>
                        <h3>Thing Name: {this.props.thingName}</h3>
                        <div className="widgets-wrapper">
                            {charts}
                        </div>
                    </section>
                </div>
            </main>
        );
    }

    private renderChart(title: string, data: any, strokeColor: string) {
        const style = { data: { stroke: strokeColor } };

        let domain: DomainTuple = [Math.min(...data)-1, Math.max(...data)+1];

        return (
            <div className="chart" key={title}>
                <h4 className="title">{title}</h4>
                <VictoryChart width={400} height={200} padding={{ top: 0, bottom: 20, left: 50, right: 20 }} >
                    <VictoryAxis crossAxis standalone={false} label="Time (s)" tickFormat={() => '' } />
                    <VictoryAxis dependentAxis crossAxis standalone={false} domain={domain} tickFormat={(e) => ''.concat(e)} style={{tickLabels: {fontSize: 11}}} />
                    <VictoryLine data={data} interpolation="monotoneX" style={style} />
                </VictoryChart>
            </div>
        );
    }
}
