/* eslint-disable no-useless-escape, quotes, react/jsx-no-comment-textnodes */
import SimpleExpander from '../../components/Expanders/SimpleExpander';
import MultiControlPane from '../../components/Controls/MultiControlPane';
import programButton from '../../images/mplabx-program-button.png';
import sw0graph from '../../images/switch0-graph.png';
import CodeView from "../../components/CodeView/CodeView";

function BlankLink(url: string, text: string): JSX.Element {
    return <a href={url} target="_blank" rel="noopener noreferrer" className="tutorial-link">{text}</a>;
}

function CodeSnippet21() {
    return (<CodeView markers={[8, 17]}>
{`// This will get called every 1 second only while we have a valid Cloud connection
static void sendToCloud(void)
{
    static char json[PAYLOAD_SIZE];
    static char publishMqttTopic[PUBLISH_TOPIC_SIZE];
    int rawTemperature = 0;
    int light = 0;
    int len = 0;
    uint8_t sw0 = SW0_GetValue() != 0;

    memset((void*)publishMqttTopic, 0, sizeof(publishMqttTopic));
    sprintf(publishMqttTopic, "%s/sensors", cid);
    // This part runs every CFG_SEND_INTERVAL seconds
    if (shared_networking_params.haveAPConnection)
    {
        rawTemperature = SENSORS_getTempValue();
        light = SENSORS_getLightValue();
        len = sprintf(json,"{"Light":%d,"Temp":%d.%02d,"Switch 0":%u}", light,rawTemperature/100,abs(rawTemperature)%100, sw0);
    }
    if (len >0)
    {
        CLOUD_publishData((uint8_t*)publishMqttTopic ,(uint8_t*)json, len);
        if (holdCount)
        {
            holdCount--;
        }
        else
        {
            ledParameterYellow.onTime = LED_BLIP;
            ledParameterYellow.offTime = LED_BLIP;
            LED_control(&ledParameterYellow);
        }
    }
}
`}</CodeView>);
}

function CodeSnippet31B() {
    return (<CodeView markers={[8, 14]}>
{`if (LED_isBlinkingGreen() == false) {
    if (!CLOUD_isConnected()) {
        LED_GREEN_SetHigh();
    } else {
        LED_GREEN_SetLow();
    }
}`}
</CodeView>);
}

let snippet221 = <div className="codesnippethighlighting" dangerouslySetInnerHTML={{
    __html: 'len = sprintf(json,"{\"Light\":%d,\"Temp\":%d.%02d<b>,\"Switch 0\":%u</b>}", light,rawTemperature/100,abs(rawTemperature)%100<b>, sw0</b>);'
}}></div>;


function CodeSnippet33() {
    return (<CodeView>
{`if (!getToggleState()) {
    LED_GREEN_SetHigh(); // Turn OFF Green LED
} else {
    LED_GREEN_SetLow(); // Turn ON Green LED
}`}</CodeView>);
}

export default function SamIotTutorials(controlsSubmit: (data: any) => void): {title: string, content: any}[] {
    return [
    {
        title: '1 Build Your Sensor Code',
        content: (
            <div>
                <p>Get started with your embedded code experience by setting up your IDE and code project.</p>
                <ol>
                    <li>Install MPLAB X IDE</li>
                    <li>Download and open the source code</li>
                    <li>Compile the code and program the board</li>
                </ol>
                <SimpleExpander collapsedTitle="See more" expandedTitle="See less" class="red" iconColor="red" iconFillColor="transparent" >
                    <h4>Before You Start</h4>
                    If you have not already, follow the {BlankLink(
                        'https://github.com/MicrochipTech/MPLAB-Harmony-Reference-Apps/releases/latest/download/SAM_IoT_WG_Development_Board_User_Guide.pdf',
                        'SAM-IoT WG Development Board User Guide'
                    )} to configure your Wi-Fi connection to view temperature and light data.
                    <ol>
                        <li>Get the Latest IDE and Compiler
                            <ul>
                                <li>{BlankLink('https://www.microchip.com/mplab/mplab-x-ide', 'MPLAB X IDE v5.40 or later')}</li>
                                <li>{BlankLink('https://www.microchip.com/mplab/compilers', 'XC32 Compiler v2.41 or later')}</li>
                                <li>{BlankLink('http://www.microchip.com/harmony', 'MPLAB® Harmony v3 Configurator v3.4.1 or later')}</li>
                            </ul>
                        </li>
                        <li>Download the project from GitHub
                            <ul>
                                <li>{BlankLink('https://github.com/MicrochipTech/MPLAB-Harmony-Reference-Apps/releases/latest/download/google_cloud_iot_core.zip', 'google_cloud_iot_core.zip')}</li>
                            </ul>
                        </li>
                        <li>Open the project in MPLAB X</li>
                        <li>Make and program the device by clicking the icon with the green
                            arrow <img id="programbutton" src={programButton} alt="Program Button" />
                            <p>If prompted, select the serial number of the SAM-IoT&nbsp;WG as the debug tool,
                                and XC32 as the compiler</p>
                        </li>
                        <li>To view the data, navigate to the CURIOSITY USB drive and click on the CLICK-ME.HTM to access the sandbox.</li>
                    </ol>
                    <p>For a comprehensive guide to installing MPLAB X, view
                        the {BlankLink('https://microchipdeveloper.com/mplabx:installation', 'Installation Guide')}.</p>
                    <p>Follow the next tutorial to dive deeper into your development.</p>
                </SimpleExpander>
            </div>
        )
    }, {
        title: '2 Add More Sensor Data',
        content: (
            <div>
                <p>Learn how to add more sensors by using the on-board buttons as a simple binary sensor and
                    displaying the sensor data on the sandbox webpage.</p>
                <ol>
                    <li>Read a button press</li>
                    <li>Send button press data to the cloud</li>
                    <li>Compile the code and program the board</li>
                </ol>

                <SimpleExpander collapsedTitle="See more" expandedTitle="See less"
                                class="red" iconColor="red" iconFillColor="transparent">
                    <p>Follow this tutorial to learn how to display more sensor data in the sandbox.
                        The data you will display is the push button status from the SW0 on-board pushbutton.</p>

                    <h4>Background</h4>
                    <p>If you haven’t already, it is recommended to complete the previous
                        tutorial <i>Build Your Sensor Code</i> to familiarize yourself with
                        MPLAB X and the project source code.</p>

                    <h4>Steps</h4>
                    <ol>
                        <li>Add functionality to your device code that detects and processes the sensor
                            <ol>
                                <li>In the source code, find the <b>sendToCloud()</b> function
                                    <p><b>Note:</b> Read the documentation in the readme.md in the
                                    source code to learn about key functions, such as SendToCloud()</p>
                                    <p><b>Pro tip:</b> control-shift-F “void sendToCloud” to find
                                    the function immediately</p>
                                </li>
                                <li><p>Create a variable for the push button:</p>
                                    <CodeView border={false}>uint8_t sw0 = SW0_GetValue() != 0;</CodeView>
                                    <p>Note: SW0_GetValue() is a function defined in PIN_MANAGER.H.
                                        Control-click on the function to navigate to the header declaration.</p>
                                </li>
                            </ol>
                        </li>
                        <li>Modify the JSON in sendToCloud() to publish the sensor data
                            <ol>
                                <li>Within the <b>sendToCloud()</b> function, find and modify
                                    the JSON message sent to the cloud to include the data from sw0:

                                    <pre style={{overflow: 'auto'}}>{snippet221}</pre>

                                    <p>Your code should now look like this:</p>
                                    <CodeSnippet21 />
                                </li>
                            </ol>
                        </li>
                        <li><b>Make and Program Your Code</b> by clicking on the green arrow
                            icon <img id="programbutton" src={programButton} alt="Program Button" /></li>
                        <li><p>
                            Open the sandbox to view the sensor data by clicking on CLICK-ME.HTM in the
                                Curiosity USB drive. Firmly press and release the pushbutton, SW0, to see
                                the data reflected in the graph.
                            </p>
                            <div>
                                <img src={sw0graph} alt="Switch 0 graph" />
                            </div>
                        </li>
                    </ol>
                </SimpleExpander>
            </div>
        )
    }, {
        title: '3 Implement a Cloud-Controlled Actuator',
        content: (
            <div>
                <p>Learn how to control the LEDs of your embedded application through the sandbox web interface.</p>
                <ol>
                    <li>Receive LED toggle state from the cloud</li>
                    <li>Write LED state to board LED</li>
                    <li>Compile the code and program the board</li>
                </ol>

                <SimpleExpander collapsedTitle="See more" expandedTitle="See less"
                                class="red" iconColor="red" iconFillColor="transparent">
                    <p>Follow this tutorial to learn how to control an “actuator” from the cloud.
                        The actuator you will control is the on-board green LED.</p>

                    <h4>Background</h4>
                    <p>If you haven’t already, it is recommended to complete the previous
                        tutorial <i>Build Your Sensor Code</i> to familiarize yourself with
                        MPLAB X and the project source code.</p>

                    <h4>Steps</h4>
                    <ol>
                        <li><p><b>Disable default green LED functionality</b></p>
                            <p>Out-of-the-box, the green LED is held high during cloud connectivity.
                                We will disable this functionality so that we can use it while
                                connected to the cloud.</p>
                            <ol>
                                <li>
                                    <p>Navigate to <code>app.c</code>, and find the
                                    function <code>APP_DataTask()</code>.</p>
                                </li>
                                <li>
                                    <p>Under <code>APP_DataTask(void)</code>, find the section:</p>
                                    <CodeSnippet31B/>
                                </li>
                                <li>
                                    <p>Add // to comment out the line</p>
                                    <CodeView border={false}>LED_Green_SetLow();</CodeView>
                                    <p>(this line holds the green LED high during cloud connection).</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p><b>Receive LED toggle state from the cloud</b></p>
                            <p>View the code in the <code>APP_ReceivedFromCloud()</code> function to see how the
                                toggle is implemented. You do not need to make modifications
                                as the toggle is already included in the source code.</p>
                        </li>
                        <li>
                            <p><b>Write LED state to board LED</b></p>
                            <p>Navigate back to the <code>APP_DataTask()</code> function and add the
                                following code at the end:</p>
                            <CodeSnippet33/>
                        </li>
                        <li>
                            <p><b>Make and Program Your Code</b> by clicking on the green arrow
                                icon <img id="programbutton" src={programButton} alt="Program Button" /></p>
                        </li>
                        <li>
                            <p><b>Control your device</b></p>
                            <p>Navigate back to the CLICK-ME.HTM page from the CURIOSITY USB drive</p>
                            <p>Using the controls below, set the toggle switch to on and click Send
                                to Device to turn the green LED to on.</p>
                        </li>
                    </ol>
                    <MultiControlPane defaultToggles={['toggle']} onSubmit={controlsSubmit}/>
                </SimpleExpander>
            </div>
        )
    }];
}
