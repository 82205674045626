import { ExpandIcon } from '../Expanders/Icon';
import Collapse from 'rc-collapse';
import 'rc-collapse/assets/index.css';
import '../../components/Expanders/rc-collapse.scss';
import { BOARDS } from '../../Constants';
import aws from '../../images/aws-logo.svg';
import gcp from '../../images/google-cloud-logo.svg';
import azure from '../../images/azure-logo.svg';

function Icon(props: object) {
    let {isActive} = props as {isActive: boolean};
    return ExpandIcon(isActive, '#4285F4', 2, 'transparent');
}

export function ProvisionDescriptions(board: BOARDS) {
    let awsPanelProps = {
        headerClass: 'rounded-header',
        className: 'rounded',
        extra: (<img className="aws" src={aws} alt="AWS logo"/>)
    };

    let gcpPanelProps = {
        headerClass: 'rounded-header',
        className: 'rounded',
        extra: (<img className="aws" src={gcp} alt="Google Cloud logo"/>)
    };

    let azurePanelProps = {
        headerClass: 'rounded-header',
        className: 'rounded',
        extra: (<img className="aws" src={azure} alt="Azure logo"/>)
    };

    let leveregeDoc = '';
    switch (board) {
        case BOARDS.avrIot:
            leveregeDoc = 'https://github.com/Leverege/microchip-avr-iot';
            break;
        case BOARDS.picIot:
            leveregeDoc = 'https://github.com/Leverege/microchip-pic-iot';
            break;
    }


    let awsSection = (
        <Collapse accordion={true} className="rounded" defaultActiveKey="0" expandIcon={Icon}>
            <Collapse.Panel header="Provision for Microchip sandbox in Amazon AWS" {...awsPanelProps}>
                Get your things on the Internet in 30 Seconds Flat with the IoT boards and the Microchip sandbox in Google Cloud.
                <ol>
                    <li><a href="https://github.com/microchip-pic-avr-tools/iotprovision-bin/releases/latest">Get the provisioning tool</a>.
                        Alternatively, install it in your Python environment with <b>pip install iotprovision</b>
                    </li>
                    <li>Connect the IoT board to the PC with a USB cable</li>
                    <li>Run the provisioning tool with these parameters <b>iotprovision -c aws -m sandbox</b></li>
                    <li>Click on the CLICK-ME.HTM on the Curiosity disk to get to the WIFI configuration page</li>
                </ol>
                <p>More information can be found <a href="https://github.com/microchip-pic-avr-solutions/microchip-iot-developer-guides-for-aws/tree/master/access-the-sandbox">here</a></p>
            </Collapse.Panel>
            <Collapse.Panel header="Provision for AWS user account (MAR)" {...awsPanelProps}>
                Get your board connected to AWS IoT Core through Multi Account Registration (MAR). The in-depth guide is available <a href="https://github.com/microchip-pic-avr-solutions/microchip-iot-developer-guides-for-aws/tree/master/connect-the-board-to-your-aws-account">here</a>.
                <p>If you already meet these prerequisites:
                <ul>
                    <li>AWS account configured</li>
                    <li>AWS CLI installed and configured</li>
                </ul>
                then you only need to follow these steps:
                </p>
                <ol>
                    <li><a href="https://github.com/microchip-pic-avr-tools/iotprovision-bin/releases/latest">Get the provisioning tool</a>.
                        Alternatively, install it in your Python environment with <b>pip install iotprovision</b>
                    </li>
                    <li>Connect the IoT board to the PC with a USB cable</li>
                    <li>Run the provisioning tool with these parameters <b>iotprovision -c aws -m mar</b></li>
                </ol>
            </Collapse.Panel>
            <Collapse.Panel header="Provision for AWS user account (JITR)" {...awsPanelProps}>
                Get your board connected to AWS IoT Core through Just In Time Registratino (JITR). The in-depth guide is available <a href="https://github.com/microchip-pic-avr-solutions/microchip-iot-developer-guides-for-aws/tree/master/a-more-thorough-look-into-the-provisioning-process">here</a>.
                <p>If you already meet these prerequisites:
                <ul>
                    <li>AWS account configured</li>
                    <li>AWS CLI installed and configured</li>
                </ul>
                then you only need to follow these steps:
                </p>
                <ol>
                    <li><a href="https://github.com/microchip-pic-avr-tools/iotprovision-bin/releases/latest">Get the provisioning tool</a>.
                        Alternatively, install it in your Python environment with <b>pip install iotprovision</b>
                    </li>
                    <li>Connect the IoT board to the PC with a USB cable</li>
                    <li>Run the provisioning tool with these parameters <b>iotprovision -c aws -m jitr</b></li>
                </ol>
            </Collapse.Panel>
        </Collapse>
    );

    let gcpSection = (
        <Collapse accordion={true} className="rounded" defaultActiveKey="0" expandIcon={Icon}>
            <Collapse.Panel header="Provision for Microchip sandbox in Google Cloud" {...gcpPanelProps}>
                <p>The Microchip sandbox account in Google Cloud offers an easy way to get started with Microchip IoT solutions. The IoT board can be connected by following these steps.</p>
                <ol>
                    <li><a href="https://github.com/microchip-pic-avr-tools/iotprovision-bin/releases/latest">Get the provisioning tool</a>.
                        Alternatively, install it in your Python environment with <b>pip install iotprovision</b>
                    </li>
                    <li>Connect the IoT board to the PC with USB cable</li>
                    <li>Run the provisioning tool with these parameters <b>iotprovision -c google -m sandbox</b></li>
                    <li>Click on the CLICK-ME.HTM on the Curiosity disk to get to the WIFI configuration page</li>
                </ol>
            </Collapse.Panel>
            <Collapse.Panel header="Provision for Google Cloud user account" {...gcpPanelProps}>
                The IoT board can be connected to any Google Cloud account by following the documentation here <a href={leveregeDoc}>{leveregeDoc}</a>
            </Collapse.Panel>
        </Collapse>
    );

    let azureSection = (
        <Collapse accordion={true} className="rounded" defaultActiveKey="0" expandIcon={Icon}>
            <Collapse.Panel header="Provision for Azure cloud" {...azurePanelProps}>
                <p>More information can be found <a href="https://github.com/Azure-Samples/Microchip-PIC-IoT-Wx">here</a>.</p>
            </Collapse.Panel>
        </Collapse>
    );

    let descriptions = board === BOARDS.picIot ? {
        aws: awsSection,
        azure: azureSection
    } : {
        aws: awsSection,
        azure: <></>
    };

    return descriptions;
}
