/* eslint-disable no-useless-escape, quotes, react/jsx-no-comment-textnodes */
import SimpleExpander from '../../components/Expanders/SimpleExpander';
import SimpleControlPane from '../../components/Controls/SimpleControlPane';
import programButton from '../../images/mplabx-program-button.png';
import sw0graph from '../../images/switch0-graph.png';
import CodeView from "../../components/CodeView/CodeView";
import { CLOUDS } from '../../Constants';

function BlankLink(url: string, text: string): JSX.Element {
    return <a href={url} target="_blank" rel="noopener noreferrer" className="tutorial-link">{text}</a>;
}

function CodeSnippet21() {
    return (<CodeView markers={[8, 17]}>
{`// This will get called every 1 second only while we have a valid Cloud connection
static void sendToCloud(void)
{
    static char json[PAYLOAD_SIZE];
    static char publishMqttTopic[PUBLISH_TOPIC_SIZE];
    int rawTemperature = 0;
    int light = 0;
    int len = 0;
    uint8_t sw0 = SW0_GetValue() != 0;

    memset((void*)publishMqttTopic, 0, sizeof(publishMqttTopic));
    sprintf(publishMqttTopic, "%s/sensors", cid);
    // This part runs every CFG_SEND_INTERVAL seconds
    if (shared_networking_params.haveAPConnection)
    {
        rawTemperature = SENSORS_getTempValue();
        light = SENSORS_getLightValue();
        len = sprintf(json,"{"Light":%d,"Temp":%d.%02d,"Switch 0":%u}", light,rawTemperature/100,abs(rawTemperature)%100, sw0);
    }
    if (len >0)
    {
        CLOUD_publishData((uint8_t*)publishMqttTopic ,(uint8_t*)json, len);
        if (holdCount)
        {
            holdCount--;
        }
        else
        {
            ledParameterYellow.onTime = LED_BLIP;
            ledParameterYellow.offTime = LED_BLIP;
            LED_control(&ledParameterYellow);
        }
    }
}
`}</CodeView>);
}

function CodeSnippet31() {
    return (<CodeView markers={[8, 14]}>
{`// Green LED if we are in Access Point
if (!shared_networking_params.amConnectingSocket)
{
    if(CLOUD_checkIsConnected())
    {
        ledParameterGreen.onTime = SOLID_ON;
        ledParameterGreen.offTime = SOLID_OFF;
        //LED_control(&ledParameterGreen);
    }
    else if(shared_networking_params.haveDataConnection == 1)
    {
        ledParameterGreen.onTime = LED_BLINK;
        ledParameterGreen.offTime = LED_BLINK;
        //LED_control(&ledParameterGreen);
    }
}`}
</CodeView>);
}


let snippet221 = <div className="codesnippethighlighting" dangerouslySetInnerHTML={{
    __html: 'len = sprintf(json,"{\"Light\":%d,\"Temp\":%d.%02d<b>,\"Switch 0\":%u</b>}", light,rawTemperature/100,abs(rawTemperature)%100<b>, sw0</b>);'
}}></div>;


function CodeSnippet33() {
    return (<CodeView>
{`if(!getToggleState()) {
    ledState.Full2Sec = LED_OFF_STATIC;
    LED_modeGreen(ledState);
}
else 
{
    ledState.Full2Sec = LED_ON_STATIC;
    LED_modeGreen(ledState); 
}
`}</CodeView>);
}

export default function AvrIotTutorials(controlsSubmit: (data: any) => void, cloud: CLOUDS): {title: string, content: any}[] {
    let gitHubLink;
    if (cloud === CLOUDS.aws) {
        gitHubLink = BlankLink('https://github.com/microchip-pic-avr-solutions/avr-iot-aws-sensor-node-mplab', 'AVR-IoT WA Application');
    } else if (cloud === CLOUDS.gcp) {
        gitHubLink = BlankLink('https://github.com/microchip-pic-avr-solutions/avr-iot-google-sensor-node-mplab', 'AVR-IoT WG Application');
    } else {
        gitHubLink = null;
    }

    return [
    {
        title: '1 Build Your Sensor Code',
        content: (
            <div>
                <p>Get started with your embedded code experience by setting up your IDE and code project.</p>
                <ol>
                    <li>Install MPLAB X IDE</li>
                    <li>Download and open the source code</li>
                    <li>Compile the code and program the board</li>
                </ol>
                <SimpleExpander collapsedTitle="See more" expandedTitle="See less" class="red" iconColor="red" iconFillColor="transparent" >
                    <h4>Before You Start</h4>
                    If you have not already, follow the Quick Start Guide to configure your Wi-Fi connection to view temperature
                    and light data.
                    <ol>
                        <li>Get the Latest IDE and Compiler
                            <ul>
                                <li>{BlankLink('https://www.microchip.com/mplab/mplab-x-ide', 'MPLAB X IDE v5.30 or later')}</li>
                                <li>{BlankLink('https://www.microchip.com/mplab/compilers', 'XC8 Compiler v2.10 or later (AVR-IoT)')}</li>
                            </ul>
                        </li>
                        <li>Download the project from GitHub
                            <ul>
                                <li>{gitHubLink}</li>
                            </ul>
                        </li>
                        <li>Open the project in MPLAB X</li>
                        <li>Make and program the device by clicking the icon with the green
                            arrow <img id="programbutton" src={programButton} alt="Program Button" />
                            <p>If prompted, select the serial number of the AVR-IoT PKOB nano as the debug tool,
                                and XC8 as the compiler</p>
                        </li>
                        <li>To view the data, navigate to the CURIOSITY USB drive and click on the CLICK-ME.HTM to access the sandbox.</li>
                    </ol>
                    <p>For a comprehensive guide to installing MPLAB X, view
                        the {BlankLink('https://microchipdeveloper.com/mplabx:installation', 'Installation Guide')}.</p>
                    <p>Follow the next tutorial to dive deeper into your development.</p>
                </SimpleExpander>
            </div>
        )
    }, {
        title: '2 Add More Sensor Data',
        content: (
            <div>
                <p>Learn how to add more sensors by using the on-board buttons as a simple binary sensor and
                    displaying the sensor data on the sandbox webpage.</p>
                <ol>
                    <li>Read a button press</li>
                    <li>Send button press data to the cloud</li>
                    <li>Compile the code and program the board</li>
                </ol>

                <SimpleExpander collapsedTitle="See more" expandedTitle="See less"
                                class="red" iconColor="red" iconFillColor="transparent">
                    <p>Follow this tutorial to learn how to display more sensor data in the sandbox.
                        The data you will display is the push button status from the SW0 on-board pushbutton.</p>

                    <h4>Background</h4>
                    <p>If you haven’t already, it is recommended to complete the previous
                        tutorial <i>Build Your Sensor Code</i> to familiarize yourself with
                        MPLAB X and the project source code.</p>

                    <h4>Steps</h4>
                    <ol>
                        <li>Add functionality to your device code that detects and processes the sensor
                            <ol>
                                <li>In the source code, find the <b>sendToCloud()</b> function
                                    <p><b>Note:</b> Read the documentation in the readme.md in the
                                    source code to learn about key functions, such as SendToCloud()</p>
                                    <p><b>Pro tip:</b> control-shift-F “void sendToCloud” to find
                                    the function immediately</p>
                                </li>
                                <li><p>Create a variable for the push button:</p>
                                    <CodeView border={false}>uint8_t sw0 = SW0_GetValue() != 0;</CodeView>
                                    <p>Note: SW0_GetValue() is a function defined in PIN_MANAGER.H.
                                        Control-click on the function to navigate to the header declaration.</p>
                                </li>
                            </ol>
                        </li>
                        <li>Modify the JSON in sendToCloud() to publish the sensor data
                            <ol>
                                <li>Within the <b>sendToCloud()</b> function, find and modify
                                    the JSON message sent to the cloud to include the data from sw0:

                                    <pre style={{overflow: 'auto'}}>{snippet221}</pre>

                                    <p>Your code should now look like this:</p>
                                    <CodeSnippet21 />
                                </li>
                            </ol>
                        </li>
                        <li><b>Make and Program Your Code</b> by clicking on the green arrow
                            icon <img id="programbutton" src={programButton} alt="Program Button" /></li>
                        <li><p>
                            Open the sandbox to view the sensor data by clicking on CLICK-ME.HTM in the
                                Curiosity USB drive. Firmly press and release the pushbutton, SW0, to see
                                the data reflected in the graph.
                            </p>
                            <div>
                                <img src={sw0graph} alt="Switch 0 graph" />
                            </div>
                        </li>
                    </ol>
                </SimpleExpander>
            </div>
        )
    }, {
        title: '3 Implement a Cloud-Controlled Actuator',
        content: (
            <div>
                <p>Learn how to control the LEDs of your embedded application through the sandbox web interface.</p>
                <ol>
                    <li>Receive LED toggle state from the cloud</li>
                    <li>Write LED state to board LED</li>
                    <li>Compile the code and program the board</li>
                </ol>

                <SimpleExpander collapsedTitle="See more" expandedTitle="See less"
                                class="red" iconColor="red" iconFillColor="transparent">
                    <p>Follow this tutorial to learn how to control an “actuator” from the cloud.
                        The actuator you will control is the on-board green LED.</p>

                    <h4>Background</h4>
                    <p>If you haven’t already, it is recommended to complete the previous
                        tutorial <i>Build Your Sensor Code</i> to familiarize yourself with
                        MPLAB X and the project source code.</p>

                    <h4>Steps</h4>
                    <ol>
                        <li>Disable default green LED functionality.
                            <p>Out-of-the-box, the green LED is held high during cloud connectivity.
                                We will disable this functionality so that we can use it while
                                connected to the cloud.</p>
                            <ol>
                                <li>
                                    <p>Navigate to <code>application_manager.c</code>, and find the
                                    function <code>MAIN_dataTask</code></p>
                                </li>
                                <li>
                                    <p>Under <code>MAIN_dataTask</code>, find the section labeled by the comment</p>
                                    <CodeView border={false}>// Green LED if we are in Access Point</CodeView>
                                </li>
                                <li>
                                    <p>Add // to comment out the two lines</p>
                                    <CodeView border={false}>LED_control(&ledParameterGreen);</CodeView>
                                    <p>(this line holds the green LED high during cloud connection).</p>
                                    <CodeSnippet31/>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>Receive LED toggle state from the cloud</p>
                            <p>View the code in the <code>receivedFromCloud</code> function to see how the
                                toggle is implemented. You do not need to make modifications
                                as the toggle is already included in the source code.</p>
                            <p>Note: Read the documentation in the readme.md in the source
                                code to learn about key functions, such as <code>receivedFromCloud()</code></p>
                        </li>
                        <li>
                            <p>Write LED state to board LED</p>
                            <p>Navigate back to the <code>MAIN_dataTask()</code> function and add the
                                following code:</p>
                                <CodeSnippet33 />
                                 <p>BEFORE the line</p>
                                <CodeView border={false}>return MAIN_DATATASK_INTERVAL;</CodeView>
                        </li>
                        <li>
                            <p><b>Make and Program Your Code</b> by clicking on the green arrow
                                icon <img id="programbutton" src={programButton} alt="Program Button" /></p>
                        </li>
                        <li>
                            <p>Navigate back to the CLICK-ME.HTM page from the CURIOSITY USB drive.</p>
                            <p>Using the controls below, set the toggle switch to on and click Send
                                to Device to turn the green LED to on.</p>
                        </li>
                    </ol>
                    <SimpleControlPane onSubmit={controlsSubmit}/>
                </SimpleExpander>
            </div>
        )
    }];
}
