import SimpleExpander from '../../components/Expanders/SimpleExpander';

export function PicAvrFamilyCollapsible() {
    return (
        <SimpleExpander collapsedTitle="PIC® & AVR® Microcontrollers" expandedTitle="PIC® & AVR® Microcontrollers" class="white">
            Microchip PIC and AVR are the two most popular microcontroller architectures.
                They are used by developers worldwide to create millions of applications that make all things around
                you <a href="https://www.microchip.com/design-centers/8-bit" target="_blank" rel="noopener noreferrer">smart</a>.
        </SimpleExpander>
    );
}

export function SamDFamilyCollapsible() {
    return (
        <SimpleExpander collapsedTitle="SAM® Microcontrollers" expandedTitle="SAM® Microcontrollers" class="white">
            <a href="https://www.microchip.com/design-centers/32-bit/sam-32-bit-mcus/sam-d-mcus" target="_blank" rel="noopener noreferrer">Microchip SAM D</a>
            {' '}Cortex®-M0+ based microcontroller (MCU) series builds on decades of innovation and experience in embedded Flash
            microcontroller technology. It not only sets a new benchmark for flexibility and ease-of-use but also combines the
            performance and energy efficiency of an ARM Cortex-M0+ based MCU with an optimized architecture and peripheral set.
        </SimpleExpander>
    );
}

export function PIC32MZW1FamilyCollapsible() {
    return (
        <SimpleExpander collapsedTitle="PIC32MZW1 Wi-Fi® MCU Module Family" expandedTitle="PIC32MZW1 Wi-Fi® MCU Module Family" class="white">
            The <a href="https://www.microchip.com/en-us/product/WFI32E01PC" target="_blank" rel="noopener noreferrer">PIC32MZ
            "W1" Wi-Fi® Module family</a> is a series of Wi-Fi modules with integrated MCU and
            advanced security designed specifically for IoT applications. It is a secure, powerful, and
            robust IoT platform used by developers worldwide to create millions of smart things around you.
        </SimpleExpander>
    );
}