export function Lightbulb(props: {fill: string}) {
   return (
      <i>
         <svg className='lightbulb' width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path fill={'url(#grad-fill-' + props.fill}
               d="m 7.945885,0.74746336 c 3.67982,-0.0360767 5.236286,2.69746454 5.366404,5.09695604 0.130118,2.3994916 -1.147151,3.3469798 -2.020293,4.2931226 -0.476298,0.910066 -0.919955,2.218715 -0.919955,2.218715 L 5.5918828,12.32018 c 0,0 -0.3387708,-1.042359 -0.7936867,-2.074408 C 3.784092,8.8890335 2.7661147,8.1434138 2.777903,5.9526493 2.7896914,3.7618849 4.2660654,0.78354002 7.945885,0.74746336 Z"
            />
            <path fill="black" d="M 2,6 C 1.9998651,1.7031764 6.3840082,-1.2009086 10.340411,0.47513789 14.296814,2.1511844 15.260687,7.3208431 12.174,10.31 c -0.203,0.196 -0.359,0.4 -0.453,0.619 l -0.762,1.769 c -0.07902,0.183243 -0.259443,0.30195 -0.459,0.302 0.666666,0 0.666666,1 0,1 0.666666,0 0.666666,1 0,1 l -0.224,0.447 C 10.106736,15.785729 9.7606657,15.999798 9.382,16 H 6.618 C 6.2393343,15.999798 5.8932635,15.785729 5.724,15.447 L 5.5,15 c -0.6666664,0 -0.6666664,-1 0,-1 -0.6666664,0 -0.6666664,-1 0,-1 -0.1999203,3.5e-4 -0.3808317,-0.118423 -0.46,-0.302 L 4.279,10.928 C 4.1700393,10.694256 4.0161102,10.48426 3.826,10.31 2.6573728,9.1808607 1.9982099,7.6250052 2,6 Z M 8,1 C 3.5061428,0.99923709 1.2926129,6.465937 4.521,9.592 4.784,9.846 5.035,10.156 5.197,10.533 L 5.83,12 h 4.342 l 0.632,-1.467 C 10.966,10.156 11.217,9.846 11.48,9.592 14.708736,6.4655998 12.494342,0.99833788 8,1 Z" />
            <defs>
               <radialGradient id="grad-fill-red" cx="40%" cy="40%">
                  <stop offset="0%" stop-color='#faa' />
                  <stop offset="100%" stop-color='#f00' />
               </radialGradient>
               <radialGradient id="grad-fill-yellow" cx="40%" cy="40%">
                  <stop offset="0%" stop-color='#ffb' />
                  <stop offset="100%" stop-color='#fd0' />
               </radialGradient>
            </defs>
         </svg>
      </i>
   );
}
