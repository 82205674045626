import FbDeviceManager from './FirebaseDeviceManager';
import { AWSDeviceManager } from './AWSDeviceManager';
import { Config } from '../Config';
import { BOARDS, CLOUDS } from '../Constants';

const { FIREBASE_BASE_URL, FIREBASE_CONFIG, AWS_WEBSOCKET_HOST_URL, AWS_CONNECTION_SECRET, ENV } = Config;

let awsDeviceManager: DeviceManager | null = null;
let fbDeviceManager: DeviceManager | null = null;

export interface DeviceManager {
    setDevice(uid: string): void;
    setOnMessage(onMessage: (this: any, data: any) => any): void;
    connect(): void;
    disconnect(): void;
    sendDeviceConfig(msg: any): void;
}

export const deviceManagerFactory = (cloud: CLOUDS, board: BOARDS): DeviceManager|null => {
    switch (cloud) {
        case CLOUDS.aws: {
            if (!awsDeviceManager) {
                awsDeviceManager = new AWSDeviceManager(
                    AWS_WEBSOCKET_HOST_URL || '',
                    AWS_CONNECTION_SECRET || '',
                    0,
                    ENV
                );
            }
            return awsDeviceManager;
        }

        case CLOUDS.gcp:
            if (!fbDeviceManager) {
                fbDeviceManager = new FbDeviceManager({
                    firebaseConfig: FIREBASE_CONFIG[board],
                    maxDataAge: 15, // age in seconds after which data is considered stale
                    firebaseUrl: FIREBASE_BASE_URL[board]
                });
            }
            return fbDeviceManager;
            
            default: 
                return null;
        }
};
