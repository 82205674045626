import {Links} from '../../components/Footer/Footer';

export const SamIotBuyLink = 'https://www.microchipdirect.com/product/EV75S95A?productLoaded=true';
export const SamIotLatestFWLink = 'https://github.com/Microchip-MPLAB-Harmony/reference_apps/releases/latest/download/google_cloud_iot_core.zip';

export const SamIotFooterLinks: Links[] = [
    {
        header: 'Documentation',
        links: [
            {
                text: 'Quick Start Guide',
                url:  'https://github.com/MicrochipTech/MPLAB-Harmony-Reference-Apps/releases/latest/download/SAM_IoT_WG_Development_Board_User_Guide.pdf'
            },{
                text: 'Embedded Source Code',
                url:  'https://github.com/MicrochipTech/MPLAB-Harmony-Reference-Apps/releases/latest/download/google_cloud_iot_core.zip'
            }
        ]
     }, {
        header: 'Rapid Development Tools',
        links: [
            {
                text: 'MPLAB Harmony v3',
                url:  'https://www.microchip.com/en-us/development-tools-tools-and-software/embedded-software-center/mplab-harmony-v3'
            }
        ]
    }, {
        header: 'Other IoT Solutions & Architecture',
        links: [
            {
                text: 'All Microchip IoT Solutions',
                url:  'https://www.microchip.com/en-us/solutions/internet-of-things'
            }
        ]
    }
];

