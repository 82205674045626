import statusBoard from '../../images/status-board.svg';
import statusWifi from '../../images/status-wifi.svg';
import statusCloud from '../../images/status-cloud.svg';
import statusStreaming from '../../images/status-streaming.svg';
import { CLOUDS } from '../../Constants';
import { ProgressLine } from './ProgressLine';
import { Symbol } from './Symbol';
import './ProgressBar.scss';

export enum STAGES {
    board = 1,
    wifi = 2,
    cloud = 3,
    streaming = 4
}

export type ProgressBarProps = {
    cloud: CLOUDS;
    stage: STAGES;
    active: boolean;
    flash:  boolean;
};

export function ProgressBar(props: ProgressBarProps) {
    let { cloud, stage, active, flash } = props;

    let color = '';
    switch (cloud) {
        case CLOUDS.aws:
            color = 'aws';
            break;
        case CLOUDS.gcp:
            color = 'gcp';
            break;
        default:
            color = 'gray';
    }

    let progClass = '';
    switch (stage) {
        case STAGES.wifi:
            progClass = 'wifi';
            break;
        case STAGES.cloud:
            progClass = 'cloud';
            break;
        case STAGES.streaming:
            progClass = 'streaming';
            break;
        default:
            progClass = '';
    }

    return (
        <div className="ProgressBar">
            <ProgressLine progress={progClass} color={color} />
            <div className="symbols">
                <Symbol icon={statusBoard}     color={color} flashing={stage === 1 && flash} active={(stage === 1 && active) || (stage > 1)} />
                <Symbol icon={statusWifi}      color={color} flashing={stage === 2 && flash} active={(stage === 2 && active) || (stage > 2)} />
                <Symbol icon={statusCloud}     color={color} flashing={stage === 3 && flash} active={(stage === 3 && active) || (stage > 3)} />
                <Symbol icon={statusStreaming} color={color} flashing={stage === 4 && flash} active={(stage === 4 && active)} />
            </div>
        </div>
    );
}
