/* eslint-disable no-useless-escape, quotes, react/jsx-no-comment-textnodes */
import SimpleExpander from '../../components/Expanders/SimpleExpander';
import SimpleControlPane from '../../components/Controls/SimpleControlPane';
import programButton from '../../images/mplabx-program-button.png';
import CodeView from "../../components/CodeView/CodeView";
import sw1graph from '../../images/switch1-graph.png';
import { ExpandIcon } from '../../components/Expanders/Icon';
import 'rc-collapse/assets/index.css';
import '../../components/Expanders/rc-collapse.scss';
import Collapse from 'rc-collapse';
const Panel = Collapse.Panel;

function BlankLink(url: string, text: string): JSX.Element {
    return <a href={url} target="_blank" rel="noopener noreferrer" className="tutorial-link">{text}</a>;
}

function Icon(props: object) {
    let {isActive} = props as {isActive: boolean};
    return ExpandIcon(isActive, '#4285F4', 2, 'transparent');
}


function CodeSnippet22Curiosity() {
    return <CodeView border={false} markers={[12, 14]}>
{`static void publishMessage() {
    /*MQTT service does not queue messages*/
    if (mqtt_appData.MQTTConnected && !mqtt_appData.MQTTPubQueued) {
        SYS_MQTT_PublishTopicCfg sMqttTopicCfg;
        int32_t retVal = SYS_MQTT_FAILURE;
        /* All Params other than the message are initialized by the config provided in MHC*/
        char pubTopic[SYS_MQTT_TOPIC_NAME_MAX_LEN] = {'\0'};
        char message[MQTT_APP_MAX_MSG_LLENGTH] = {'\0'};

        if (!mqtt_appData.shadowUpdate) { /*if a shadow update is requested, do it in this round*/
            snprintf(pubTopic, SYS_MQTT_TOPIC_NAME_MAX_LEN, "%s/sensors", app_controlData.mqttCtrl.clientId);
            //sprintf(message, MQTT_APP_TELEMETRY_MSG_TEMPLATE, (int) app_controlData.adcData.temp);
            /*Graduation step to include an additional sensor data. Comment out the above line and uncomment the one below.*/
            sprintf(message, MQTT_APP_TELEMETRY_MSG_GRAD_TEMPLATE, (int) app_controlData.adcData.temp,app_controlData.switchData.switchStatus);
`}</CodeView>;
}

function CodeSnippet22WFI32IoT() {
    return <CodeView border={false} markers={Array.from(Array(51-38).keys(), n => n + 38 - 1)}>
{`static int publishMessage()
{
    int status = 0;
    IotMqttError_t publishStatus = IOT_MQTT_STATUS_PENDING;
    IotMqttPublishInfo_t publishInfo = IOT_MQTT_PUBLISH_INFO_INITIALIZER;
    IotMqttCallbackInfo_t publishComplete = IOT_MQTT_CALLBACK_INFO_INITIALIZER;
    char pPublishPayload[ APP_AWS_MAX_MSG_LLENGTH ];   
    char pubTopic[APP_AWS_TOPIC_NAME_MAX_LEN];
    const char * pPublishTopics[ PUBLISH_TOPIC_COUNT ] =
    {
        pubTopic,
    };
    
    if(appAwsData.shadowUpdate)
        snprintf(pPublishTopics[0], APP_AWS_TOPIC_NAME_MAX_LEN, APP_AWS_SHADOW_UPDATE_TOPIC_TEMPLATE, g_Aws_ClientID);
    else
        snprintf(pPublishTopics[0], APP_AWS_TOPIC_NAME_MAX_LEN, "%s/sensors", g_Aws_ClientID);

    publishComplete.function = operationCompleteCallback;
    publishComplete.pCallbackContext = NULL;

    /* Set the common members of the publish info. */
    publishInfo.qos = IOT_MQTT_QOS_1;
    publishInfo.topicNameLength = strlen(pPublishTopics[0]);
    publishInfo.pPayload = pPublishPayload;
    publishInfo.retryMs = PUBLISH_RETRY_MS;
    publishInfo.retryLimit = PUBLISH_RETRY_LIMIT;
    publishInfo.pTopicName = pPublishTopics[0];
    
    APP_AWS_DBG(SYS_ERROR_INFO, "Publishing message\r\n");

    /* Generate the payload for the PUBLISH. */
    if(appAwsData.shadowUpdate){
        status = snprintf( pPublishPayload, APP_AWS_MAX_MSG_LLENGTH, APP_AWS_SHADOW_MSG_TEMPLATE, !LED_YELLOW_Get());
        appAwsData.shadowUpdate = false;
    }
    else
#if 1
        status = snprintf( pPublishPayload, APP_AWS_MAX_MSG_LLENGTH,
                APP_AWS_TELEMETRY_MSG_TEMPLATE, 
                APP_readTemp(),
                APP_readLight());
#else
        /*Graduation step to include an additional sensor data. 
        Comment out the above code block by changing the '#if 1' to '#if 0'*/
        status = snprintf( pPublishPayload, APP_AWS_MAX_MSG_LLENGTH,
                APP_AWS_TELEMETRY_MSG_GRAD_TEMPLATE, 
                APP_readTemp(),
                APP_readLight(),
                !SWITCH1_Get());
#endif`}</CodeView>;
}


function CodeSnippet322Curiosity() {
    return <CodeView border={false} markers={[36, 37, 38, 39, 40]}>
{`int32_t MqttCallback(SYS_MQTT_EVENT_TYPE eEventType, void *data, uint16_t len, void* cookie) {
    static int errorCount = 0;
    switch (eEventType) {
        case SYS_MQTT_EVENT_MSG_RCVD:
        {
            SYS_MQTT_PublishConfig *psMsg = (SYS_MQTT_PublishConfig *) data;
            psMsg->message[psMsg->messageLength] = 0;
            psMsg->topicName[psMsg->topicLength] = 0;
            //SYS_CONSOLE_PRINT("\\nMqttCallback(): Msg received on Topic: %s ; Msg: %s\\r\\n",psMsg->topicName, psMsg->message);

            if (NULL != strstr((char*) psMsg->topicName, "/shadow/update/delta")) {
                cJSON *messageJson = cJSON_Parse((char*) psMsg->message);
                if (messageJson == NULL) {
                    const char *error_ptr = cJSON_GetErrorPtr();
                    if (error_ptr != NULL) {
                        SYS_CONSOLE_PRINT(TERM_RED"Message JSON parse Error. Error before: %s\\n"TERM_RESET, error_ptr);
                    }
                    cJSON_Delete(messageJson);
                    break;
                }

                //Get the desired state
                cJSON *state = cJSON_GetObjectItem(messageJson, "state");
                if (!state) {
                    cJSON_Delete(messageJson);
                    break;
                }

                //Get the toggle state
                cJSON *toggle = cJSON_GetObjectItem(state, "toggle");
                if (!state) {
                    cJSON_Delete(messageJson);
                    break;
                }

                bool desiredState = (bool) toggle->valueint;
                if (desiredState) {
                    LED_GREEN_On();
                    SYS_CONSOLE_PRINT(TERM_GREEN"LED ON\\r\\n"TERM_RESET);
                } else {
                    LED_GREEN_Off();
                    SYS_CONSOLE_PRINT(TERM_YELLOW"LED OFF\\r\\n"TERM_RESET);
                }
                cJSON_Delete(messageJson);`}</CodeView>;
}

function CodeSnippet322WFI32IoT() {
    return <CodeView border={false} markers={Array.from(Array(58-50).keys(), n => n + 50 - 1)}>
{`/* Publish message is received */
static void MqttCallback( void * param1,
                                       IotMqttCallbackParam_t * const pPublish )
{
    const char * pPayload = pPublish->u.message.info.pPayload;
    
    if (NULL != strstr(pPublish->u.message.info.pTopicName, "/shadow/update/delta")) {
        /* Print information about the incoming PUBLISH message. */
        APP_AWS_DBG(SYS_ERROR_DEBUG,  "Incoming PUBLISH received:\\r\\n"
                    "Subscription topic filter: %.*s\\r\\n"
                    "Publish topic name: %.*s\\r\\n"
                    "Publish retain flag: %d\\r\\n"
                    "Publish QoS: %d\\r\\n"
                    "Publish payload: %.*s \\r\\n",
                    pPublish->u.message.topicFilterLength,
                    pPublish->u.message.pTopicFilter,
                    pPublish->u.message.info.topicNameLength,
                    pPublish->u.message.info.pTopicName,
                    pPublish->u.message.info.retain,
                    pPublish->u.message.info.qos,
                    pPublish->u.message.info.payloadLength,
                    pPayload );
        
        APP_AWS_DBG(SYS_ERROR_DEBUG, "%.*s \\r\\n",pPublish->u.message.info.payloadLength, pPayload);
    
        cJSON *messageJson = cJSON_Parse(pPayload);
        if (messageJson == NULL) {
            const char *error_ptr = cJSON_GetErrorPtr();
            if (error_ptr != NULL) {
                APP_AWS_DBG(SYS_ERROR_ERROR, "Message JSON parse Error. Error before: %s \\r\\n", error_ptr);
            }
            cJSON_Delete(messageJson);
            return;
        }

        //Get the desired state
        cJSON *state = cJSON_GetObjectItem(messageJson, "state");
        if (!state) {
            cJSON_Delete(messageJson);
            return;
        }
        
        //Get the toggle state
        cJSON *toggle = cJSON_GetObjectItem(state, "toggle");
        if (!state) {
            cJSON_Delete(messageJson);
            return;
        }

        bool desiredState = (bool)toggle->valueint;
        if (desiredState) {
            APP_AWS_PRNT("LED ON \\r\\n");
            APP_manageLed(LED_YELLOW, LED_S_BLINK_STARTING_ON, BLINK_MODE_SINGLE);
        } else {
            APP_AWS_PRNT("LED OFF \\r\\n");
            APP_manageLed(LED_YELLOW, LED_S_BLINK_STARTING_OFF, BLINK_MODE_SINGLE);
        }
        cJSON_Delete(messageJson);
`}</CodeView>;
}           

export default function Pic32mzw1Tutorials(controlsSubmit: (data: any) => void): {title: string, content: any}[] {
    return [
    {
        title: '1 Build Your Sensor Code',
        content: (
            <div>
                <p>Get started with your embedded code experience by setting up your IDE and code project.</p>
                <ol>
                    <li>Install MPLAB X IDE</li>
                    <li>Download and open the source code</li>
                    <li>Compile the code and program the board</li>
                </ol>
                <SimpleExpander collapsedTitle="See more" expandedTitle="See less" class="red" iconColor="red" iconFillColor="transparent" >
                    <h4>Before You Start</h4>
                    If you have not already, follow
                    the {BlankLink('https://github.com/MicrochipTech/PIC32MZW1_Curiosity_OOB/blob/master/README.md', 'Curiosity')} /
                    {' '}{BlankLink('https://github.com/MicrochipTech/WFI32-IoT/blob/master/README.md', 'WFI32-IoT')} board guide
                    to configure your Wi-Fi connection to view temperature and light data.
                    <ol>
                        <li>Get the Latest IDE and Compiler
                            <ul>
                                <li>{BlankLink('https://www.microchip.com/mplab/mplab-x-ide', 'MPLAB X IDE v5.40 or later')}</li>
                                <li>{BlankLink('https://www.microchip.com/mplab/compilers', 'XC32 Compiler v2.41 or later (AVR-IoT)')}</li>
                            </ul>
                        </li>
                        <li>Download the{' '}
                            {BlankLink('https://github.com/MicrochipTech/PIC32MZW1_Curiosity_OOB/releases', 'Curiosity')}{' / '}
                            {BlankLink('https://github.com/MicrochipTech/WFI32-IoT/releases', 'WFI32-IoT')}{' '}
                            project from GitHub.
                        </li>
                        <li>Open the project in MPLAB X</li>
                        <li>Make and program the device by clicking the icon with the green
                            arrow <img id="programbutton" src={programButton} alt="Program Button" />
                            <p>If prompted, select the serial number of the Starter Kit PKOB as the debug tool,
                                and XC32 as the compiler</p>
                        </li>
                        <li>To view the data, navigate to the USB drive and click on the clickme.html to access the sandbox.</li>
                    </ol>
                    <p>For a comprehensive guide to installing MPLAB X, view
                        the {BlankLink('https://microchipdeveloper.com/mplabx:installation', 'Installation Guide')}.</p>
                    <p>Follow the next tutorial to dive deeper into your development.</p>
                </SimpleExpander>
            </div>
        )
    }, {
        title: '2 Add More Sensor Data',
        content: (
            <div>
                <p>Learn how to add more sensors by using the on-board buttons as a simple binary sensor and
                    displaying the sensor data on the sandbox webpage.</p>
                <ol>
                    <li>Read a button press</li>
                    <li>Send button press data to the cloud</li>
                    <li>Compile the code and program the board</li>
                </ol>

                <SimpleExpander collapsedTitle="See more" expandedTitle="See less"
                                class="red" iconColor="red" iconFillColor="transparent">
                    <p>Follow this tutorial to learn how to display more sensor data in the sandbox.
                        The data you will display is the push button status from the SW1 on-board pushbutton.</p>

                    <h4>Background</h4>
                    <p>If you haven’t already, it is recommended to complete the previous
                        tutorial <i>Build Your Sensor Code</i> to familiarize yourself with
                        MPLAB X and the project source code.</p>

                    <h4>Steps</h4>
                    <ol>
                        <li>
                            <p>Add functionality to your device code that detects and processes the sensor</p>
                            <ul>
                                <li>
                                    In the source code, find the <strong>publishMessage()</strong> function. In MPLAB X,
                                    you can use control-shift-F ‘publishMessage’ to find the function immediately.
                                </li>
                            </ul>
                        </li>
                        <li>Uncomment the graduation code to enable sending the switch position data to the cloud.</li>
                        <Collapse accordion={true} className="slim" expandIcon={Icon}>
                            <Panel header="Curiosity Board" headerClass="slim-header"  className="slim">
                            <CodeSnippet22Curiosity />
                            </Panel>
                            <Panel header="WFI32-IoT" headerClass="slim-header" className="slim">
                                <CodeSnippet22WFI32IoT />
                            </Panel>
                        </Collapse>
                        <li>
                            Make and Program Your Code by clicking on the green arrow icon
                            <img id="programbutton" src={programButton} alt="Program Button" />
                        </li>
                        <li>
                            <p>
                                Open the sandbox to view the sensor data by clicking on clickme.html in the newly enumerated USB drive. Firmly press
                                and release the pushbutton, SW1, to see the data reflected in the graph.
                            </p>
                            <img className="contrast" style={{ marginTop: '20px', width: 400 }} alt="" src={sw1graph} />
                        </li>
                    </ol>
                </SimpleExpander>
            </div>
        )
    }, {
        title: '3 Implement a Cloud-Controlled Actuator',
        content: (
            <div>
                <p>Learn how to control the LEDs of your embedded application through the sandbox web interface.</p>
                <ol>
                    <li>Receive LED toggle state from the cloud</li>
                    <li>Write LED state to board LED</li>
                    <li>Compile the code and program the board</li>
                </ol>

                <SimpleExpander collapsedTitle="See more" expandedTitle="See less"
                                class="red" iconColor="red" iconFillColor="transparent">
                    <p>Follow this tutorial to learn how to control an “actuator” from the cloud.
                        The actuator you will control is the on-board LED.</p>

                    <h4>Background</h4>
                    <p>If you haven’t already, it is recommended to complete the previous
                        tutorial <i>Build Your Sensor Code</i> to familiarize yourself with
                        MPLAB X and the project source code.</p>

                    <h4>Steps</h4>
                    <ol>
                        <li>
                        <p>The function of LED control has been added to the out-of-box application,
                            so no software modification is needed. Using the controls below, set the toggle
                            switch to on and click Send to Device to turn the LED on.
                        </p>
                        <SimpleControlPane onSubmit={controlsSubmit}/>
                        </li>
                        <li>
                        If you want to understand the details of this function&apos;s source code, check below:
                        <ol>
                            <li>
                                In the firmware, the device subscribes to the AWS shadow delta update topic
                                while establishing a connection to the AWS IoT service.
                            </li>
                            <li>
                                When a message is received, the firmware parses it and takes the appropriate action.
                                In the source code, find the MqttCallback() function. In MPLAB X, you can use
                                control-shift-F ‘MqttCallback’ to find the function immediately.
                                <Collapse accordion={true} className="slim" expandIcon={Icon}>
                                    <Panel header="Curiosity Board" headerClass="slim-header" className="slim">
                                <CodeSnippet322Curiosity/>
                                    </Panel>
                                    <Panel header="WFI32-IoT" headerClass="slim-header" className="slim">
                                        <CodeSnippet322WFI32IoT/>
                                    </Panel>
                                </Collapse>
                            </li>
                        </ol>
                        </li>
                    </ol>
                </SimpleExpander>
            </div>
        )
    }];
}
