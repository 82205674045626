import {
    BrowserRouter as Router,
    Switch,
    Redirect,
    Route,
    useLocation,
    useRouteMatch
} from 'react-router-dom';
import './App.css';
import ReactGA from 'react-ga';
import AvrIotPage from './pages/AvrIot/AvrIot';
import PicIotPage from './pages/PicIot/PicIot';
import SamIotPage from './pages/SamIot/SamIot';
import Pic32mzw1 from './pages/Pic33mzw1/Pic32mzw1';
import AvrIotCellularMiniPage from './pages/AvrIotCellularMini/AvrIotCellularMini';
import WifiConfig from './pages/WifiConfig/WifiConfig';
import Frontpage from './pages/Frontpage/Frontpage';
import NotFound from './pages/NotFound/NotFound';
import { BOARDS } from './Constants';

import { useEffect } from 'react';

function usePageViews() {
    let location = useLocation();
    useEffect(() => {
        let ga: string = (window as any).ga_id;
        if (ga.length > 8) {
            ReactGA.pageview(location.pathname + location.search);
        }
    }, [location]);
}

function Routes() {
    usePageViews();

    return <Switch>
        <Route exact path="/"                      component={Frontpage}/>

        <Route exact path="/avr-iot"               component={AvrIotPage} />
        <Route exact path="/avr-iot/:cloud/:id"    component={() => <RedirectOldUrl board={BOARDS.avrIot} />} />

        <Route exact path="/pic-iot"               component={PicIotPage} />
        <Route exact path="/pic-iot/:cloud/:id"    component={() => <RedirectOldUrl board={BOARDS.picIot} />} />

        <Route exact path="/pic32mzw1"             component={Pic32mzw1} />
        <Route exact path="/pic32mzw1/:cloud/:id"  component={() => <RedirectOldUrl board={BOARDS.pic32mzw1} />} />

        <Route exact path="/sam-iot"               component={SamIotPage} />
        <Route exact path="/sam-iot/:cloud/:id"    component={() => <RedirectOldUrl board={BOARDS.samIot} />} />

        <Route exact path="/avr-iot-cellular-mini" component={AvrIotCellularMiniPage} />

        <Route exact path="/wificfg"               component={WifiConfig} />

        <Route exact path="/device/:id"           component={RedirectDirectLinks} />

        <Route                                     component={NotFound} />
    </Switch>;
}

type RedirectOldUrlProps = {
    board: BOARDS
}

function RedirectOldUrl(props: RedirectOldUrlProps) {
    type MatchParams = {
        id: string;
        cloud: string;
    }

    let match = useRouteMatch<MatchParams>();
    let id = match.params.id;
    let cloud = match.params.cloud;
    let url = `/${props.board}?cloud=${cloud}&id=${id}`;

    return <Redirect to={url} />;
}

function RedirectDirectLinks() {
    type MatchParams = {
        id: string;
    }

    const match = useRouteMatch<MatchParams>();
    const id = match.params.id;
    
    const currentUrl = window.location.href;
    let url;

    if (currentUrl.startsWith('https://avr-iot.com/')) {
        url = `https://iot.microchip.com/avr-iot?cloud=gcp&id=${id}`;
    } else if (currentUrl.startsWith('https://pic-iot.com/')) {
        url = `https://iot.microchip.com/pic-iot?cloud=gcp&id=${id}`;
    } else {
        url = 'https://iot.microchip.com/';
    }

    window.location.href = url;
    return null;
}

function App() {
    // Assume real Google Analytics IDs are longer than 8 characters,
    // so ignore string replacement placeholders, empty strings etc.
    // The correct GA ID will be set in the production environment in AWS
    let ga: string = (window as any).ga_id;
    if (ga.length > 8) {
        ReactGA.initialize(ga, { debug: false });
    }

    return (
        <Router>
            <Routes/>
        </Router>
    );
}

export default App;
