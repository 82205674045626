import statusBoard from '../../images/status-board.svg';
import statusDocs from '../../images/status-docs.svg';
import statusCloud from '../../images/status-cloud.svg';
import statusStreaming from '../../images/status-streaming.svg';
import { CLOUDS } from '../../Constants';
import { ProgressLine } from './ProgressLine';
import { Symbol } from './Symbol';
import './ProgressBarMini.scss';

export enum STAGES {
	board = 1,
	cloud = 2,
	streaming = 3,
	docs = 4
}

export type ProgressBarMiniProps = {
	cloud: CLOUDS;
	stage: STAGES;
	active: boolean;
	flash: boolean;
};

export function ProgressBarMini(props: ProgressBarMiniProps) {
	let { cloud, stage, active, flash } = props;

	let color = '';
	switch (cloud) {
		case CLOUDS.aws:
			color = 'aws';
			break;
		case CLOUDS.gcp:
			color = 'gcp';
			break;
		default:
			color = 'gray';
	}

	let progClass = '';
	switch (stage) {
		case STAGES.cloud:
			progClass = 'cloud';
			break;
		case STAGES.streaming:
			progClass = 'streaming';
			break;
		case STAGES.docs:
			progClass = 'docs';
			break;
		default:
			progClass = '';
	}

	return (
		<div className="ProgressBarMini">
			<ProgressLine progress={progClass} color={color} mini />
			<div className="symbols">
				<Symbol icon={statusBoard} color={color} flashing={stage === 1 && flash} active={(stage === 1 && active) || (stage > 1)} />
				<Symbol icon={statusCloud} color={color} flashing={stage === 2 && flash} active={(stage === 2 && active) || (stage > 2)} />
				<Symbol icon={statusStreaming} color={color} flashing={stage === 3 && flash} active={(stage === 3 && active) || (stage > 3)} />
				<Symbol icon={statusDocs} color={'green'} flashing={stage === 4 && flash} active={(stage === 4 && active)} link="https://iot.microchip.com/docs" />
			</div>
		</div>
	);
}
