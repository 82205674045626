import './ChooseBoard.scss';
import pic32mzw1_curiosity from '../../images/pic32mz-angle.jpg';
import pic32mz_wfi32 from '../../images/pic32mz_wfi32.png';
import { OutboundLink } from '../../components/Links/Links';

export default function ChooseBoard() {
    return (
        <div className="ChooseBoard">
            <section className='content-wrapper'>
                <h3>IoT Development Boards</h3>
                <p>Pick your development board below to see all of the exciting
                    things you can do with the PIC32MZ "W1" Modules!</p>
                <div className="boards">
                    <div className="board">
                        <img className="curiosity" src={pic32mzw1_curiosity} alt="PIC32MZW1 Curiosity Board" />
                        <p>PIC32MZW1 Curiosity Board</p>
                        <div className="btn red">
                            <OutboundLink to="https://www.microchipdirect.com/product/EV12F11A" eventLabel="Buy link for PIC32MZW1">Buy Now</OutboundLink>
                        </div>
                    </div>
                    <div className="board">
                        <img className="wfi32" src={pic32mz_wfi32} alt="WFI32-IoT Board" />
                        <p>WFI32-IoT Board</p>
                        <div className="btn red">
                            <OutboundLink to="https://www.microchipdirect.com/dev-tools/EV36W50A" eventLabel="Buy link for WFI32-IoT">Buy Now</OutboundLink>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
