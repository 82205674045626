import React from 'react';
import { SerialController } from 'avr-serial-terminal';
import { isChrome, isMobile, isEdge, isOpera } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlaskVial } from '@fortawesome/free-solid-svg-icons';


export default function BoardStatusMonitor(props: { usbVendorId: number, baudRate: number }) {

	const connector = React.useRef<any>(undefined);

	const [connected, setConnected] = React.useState(false);
	const [terminalData, setTerminalData] = React.useState<Uint8Array>(new Uint8Array());


	const newData = (data: Uint8Array) => {
		setTerminalData((prevState) => {
			let tmp = new Uint8Array(prevState.length + data.length);
			tmp.set(new Uint8Array(prevState), 0);
			tmp.set(new Uint8Array(data), prevState.length);
			return tmp;
		});
	};

	const connect = () => {
		if (connector.current === undefined || connector === undefined) {
			console.error('Connector undefined');
		}
		connector.current?.open().then(() => {
			console.log('Device Opened');
			setConnected(true);
			read();
		}).catch((e: any) => {
			console.error('could not open port ' + e);
		});
	};

	const disconnect = () => {
		connector.current.close().then(() => {
			console.log('Device Closed');
		}).catch((e: any) => {
			console.error('Could not close ' + e);
		}).finally(() => {
			setConnected(false);
		});
	};

	const read = () => {
		connector.current.startRead();
	};

	const isSupported = (): boolean => {
		if (isMobile) {
			return false;
		}

		return isChrome || isEdge || isOpera;
	};

	const decodedData = (): string => {
		return new TextDecoder('ascii', { fatal: true }).decode(terminalData);
	};



	React.useEffect(() => {
		connector.current = new SerialController({ usbVendorId: props.usbVendorId, baudRate: props.baudRate });
		connector.current.onNewData = newData;
		connector.current.ondisconnect = disconnect;
	}, [props.baudRate, props.usbVendorId]);

	React.useEffect(() => {
		return () => {
			if (connector.current === undefined) {
				return;
			}
			if (connector.current.connected) {
				console.log('Closing');
				connector.current.close();
			}
		};
	}, []);

	if (!isSupported()) {
		return (
			<React.Fragment>
				<h3>Board Serial Output</h3>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<FontAwesomeIcon style={{ fontSize: '2rem' }} icon={faFlaskVial} />
					<b style={{ paddingLeft: '1rem' }}>The Serial Terminal does not support Firefox, you are not able to read log messages from the board. Please use Google Chrome for the best experience.</b>
				</div>

			</React.Fragment>
		);
	}


	return (
		<React.Fragment>
			<h3>Board Serial Output</h3>
			<p>Connect to your board by clicking <i>Connect Board</i> to get debug messages as you progress</p>
			<div style={{ backgroundColor: 'rgba(216, 216, 216, 0.2)', minHeight: '10px', padding: '20px', marginBottom: '20px', whiteSpace: 'pre-wrap' }}>
				{decodedData()}
			</div>
			<div>
				{(() => {
					if (!connected) {
						return <button className='btn red' onClick={() => { connect(); }}>Connect Board</button>;
					} else {
						return (<div>
							<button className='btn red-inverted' onClick={() => { disconnect(); }}>Disconnect Board</button>
							<button className='btn red' style={{ marginLeft: '15px' }} onClick={() => { setTerminalData(new Uint8Array()); }}>Clear</button>
						</div>);
					}
				})()}
			</div>
		</React.Fragment>

	);
}