import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import gfm from 'remark-gfm';

export default function MarkdownContent(props: { content: string }) {

	return (
		<ReactMarkdown
			remarkPlugins={[remarkBreaks, gfm]}
		>
			{props.content}
		</ReactMarkdown>
	);
}