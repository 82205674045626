import trustGoModule from '../../images/trust_go_module.png';

const Link = (props: any) => {
    const { href, text } = props;

    return (
        <a
            onClick={e => e.stopPropagation()}
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            className="overview-link">
            {text}
        </a>
    );
};


export default function Pic32MZW1Overview() {
    return (
        <section className='overview'>
            <section className='content-wrapper'>
                <div className="content">
                    <div className="left">
                        <h3>Trust&amp;Go Wi-Fi MCU module</h3>
                        <p>
                            <Link href="http://www.microchip.com/wwwproducts/en/WFI32E01PC" text="WFI32E01PC" /> is
                            a class leading Wi-Fi MCU module, combining high-performance 32-bit MCU, robust Wi-Fi,
                            and advanced Trust&amp;Go security. WFI32E01PC contains a highly efficient MCU Core
                            with numerous high-precision peripherals, such as dual 12-bit ADCs, integrating three
                            systems elements into one!
                        </p>
                    </div>
                    <div className="overview-image">
                        <img alt="Trust and Go Module" src={trustGoModule} style={{height: 'auto', width: '160px'}} />
                    </div>
                </div>

                <div className="content">
                    <div className="left">
                        <h3>Security</h3>
                        <p>
                            The WFI32E01PC Wi-Fi module features{' '}
                            <Link href="https://www.microchip.com/en-us/products/security/trust-platform/trust-and-go" text="Trust&amp;Go" />
                            {' '}technology, which provides advanced hardware security and authentication. It is pre-provisioned
                            for three leading cloud platforms; AWS, GCP, and Azure.
                        </p>
                    </div>
                    <div className="overview-image">
                        <img alt="Trust and Go badge" style={{height: 'auto', width: '110px', marginRight: '15px'}}
                            src="https://www.microchip.com/images/default-source/logos/serviceplan-logo-trustandgo-whitebg.tmb-medium.png?sfvrsn=73f2a31b_1" />
                    </div>
                </div>

                <div className="content">
                    <div className="left">
                        <h3>Easy-to-use development tool</h3>
                        <p><Link href="https://www.microchip.com/mplab/mplab-harmony" text="MPLAB Harmony" /> is a fully
                            integrated embedded software development framework that provides flexible and interoperable
                            software modules to simplify the development of value-added features, such as WLAN,
                            and reduce your product’s time to market.
                        </p>
                    </div>
                    <div className="overview-image">
                        <img alt="MPLAB Harmony Badge" style={{height: 'auto', width: '140px'}}
                            src="https://media-s3-us-east-1.ceros.com/microchip-technology/images/2020/05/12/357b5ed7b8456aacb644f19be46ac7db/mplab-harmony-shield.png" />
                    </div>
                </div>
            </section>
        </section>
    );
}
