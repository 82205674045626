import { useLocation }             from 'react-router-dom';
import TopNav                      from '../../components/TopNav/TopNav';
import WifiConfigBox               from '../../components/Wifi/WirelessNetworkConnection';
import { Footer }                  from '../../components/Footer/Footer';
import { BOARDS, CLOUDS }          from '../../Constants';
import '../../Landingpage.scss';
import './WifiConfig.scss';

export type WifiConfigProps = {

};

export default function WifiConfig(props: WifiConfigProps) {
    const search = useLocation().search;
    const wpa3 = new URLSearchParams(search).get('wpa3');

    let hasWpa3 = wpa3 === null ? false : true;

    return (
        <div className="landingpage">
            <TopNav cloud={ CLOUDS.none } />
            <div className="welcome">
                <div className="content-wrapper">
                    <div className="content">
                        <div className="left">
                            <h2>WiFi Configuratotion</h2>
                            <h5>This page will help you get your board connected to your wireless network.</h5>
                            <p className="welcome-info-text">The configurator below will generate a wifi
                                configuration file that you can drag and drop onto your connected IoT board.
                            </p>
                        </div>                        
                    </div>
                </div>
            </div>
            <WifiConfigBox hasWPA3={hasWpa3} showAlert={false} />
            <Footer links={[]} board={BOARDS.none}/>
        </div>
    );
}
