import React from 'react';
import TopNav from '../../components/TopNav/TopNav';
import Welcome  from '../../components/Welcome/Welcome';
import Visualizer from '../../components/Visualizer/Visualizer';
import WifiConfig from '../../components/Wifi/WirelessNetworkConnection';
import Overview from '../../components/Overview/Overview';
import WhatsNext from '../../components/WhatsNext/WhatsNext';
import { SamDFamilyCollapsible } from '../../components/McuFamilyCollapsibles/McuFamilyCollapsibles';
import { DeviceManager, deviceManagerFactory } from '../../utils/DeviceManagerFactory';
import { BOARDS, CLOUDS } from '../../Constants';
import '../../Landingpage.scss';
import { ProgressBar, ProgressBarProps, STAGES } from '../../components/ProgressBar/ProgressBar';
import SamIotTutorials from './SamIotTutorials';
import {Footer} from '../../components/Footer/Footer';
import { SamIotBuyLink, SamIotLatestFWLink, SamIotFooterLinks } from './SamIotLinks';
import './SamIot.scss';

type SamIotState = {
    thingName: string,
    cloud: CLOUDS;
    streamTimeout?: NodeJS.Timeout,
    mode: string,
    data: any[],
    toggle: boolean
}

export default class SamIotPage extends React.Component<{}, SamIotState>{
    deviceManager: DeviceManager|null;

    constructor(props: any) {
        super(props);

        let search = new URLSearchParams(props.location.search);
        const id = search.get('id');

        let cloud;
        switch (search.get('cloud')?.toLowerCase()) {
            case CLOUDS.gcp:
                cloud = CLOUDS.gcp;
                break;
                
            case CLOUDS.aws:
                cloud = CLOUDS.aws;
                break;

                default:
                cloud = CLOUDS.none;
                break;
        }

        const thingName = id ? id.toLowerCase() : '';
        this.deviceManager = deviceManagerFactory(cloud, BOARDS.samIot);
        this.deviceManager?.setDevice(thingName);

        this.state = {
            thingName: thingName,
            cloud,
            streamTimeout: undefined,
            mode: thingName !== '' && cloud !== CLOUDS.none ? 'init' : 'unknown',
            data: [],
            toggle: false
        };
    }

    componentDidMount() {
        if (this.state.mode !== 'unknown') {
            const timeout = setTimeout(() => {
                this.setState({ mode: 'config' });
            }, 3000);
            this.setState({ streamTimeout: timeout });
            
            this.deviceManager?.setOnMessage((data) => {
                // listen to data sent from the websocket server
                data = this.state.data.slice(-20).concat(data);
                
                if (this.state.streamTimeout) {
                    clearTimeout(this.state.streamTimeout);
                }
                const timeout = setTimeout(() => {
                    this.setState({ mode: 'config' });
                }, 5000);
                
                this.setState({
                    streamTimeout: timeout,
                    mode: 'visualizer',
                    data: data
                });
            });
            
            this.deviceManager?.connect();
        }
    }

    controlsSubmitHandler(data: any) {
        this.deviceManager?.sendDeviceConfig(data);
    }

    render() {
        const wifiConfig = this.state.mode === 'config'     ? <WifiConfig /> : null;
        const visualizer = this.state.mode === 'visualizer' ? <Visualizer thingName={this.state.thingName} data={this.state.data} cloud={this.state.cloud} /> : null;
        const showUpgrade = this.state.thingName !== '';

        let pbProps: ProgressBarProps;
        switch (this.state.mode) {
            default:
            case 'unknown':
                pbProps = {
                    cloud: this.state.cloud,
                    stage: STAGES.board,
                    active: false,
                    flash: false
                };
                break;

            case 'init':
                pbProps = {
                    cloud: this.state.cloud,
                    stage: STAGES.wifi,
                    active: false,
                    flash: true
                };
                break;

            case 'config':
                pbProps = {
                    cloud: this.state.cloud,
                    stage: STAGES.wifi,
                    active: false,
                    flash: false
                };
                break;

            case 'visualizer':
                pbProps = {
                    cloud: this.state.cloud,
                    stage: STAGES.streaming,
                    active: true,
                    flash: false
                };
                break;
        }

        const progressBar = ProgressBar(pbProps);
        
        return (
            <div className="landingpage">
                <TopNav cloud={ this.state.cloud } />
                <Welcome title="SAM-IoT Development Boards" boardNameAorAn="a" boardName="SAM-IoT" board={BOARDS.samIot}
                    buyLink={SamIotBuyLink}
                    fwLink={SamIotLatestFWLink}
                    cloud={this.state.cloud}
                    familyDropdown={SamDFamilyCollapsible()}
                    showUpgrade={showUpgrade}
                    progressBar={progressBar}
                    />
                { wifiConfig }
                { visualizer }
                <Overview board={BOARDS.samIot} cloud={this.state.cloud} />
                <WhatsNext tutorials={SamIotTutorials((data) => this.controlsSubmitHandler(data))} board={BOARDS.samIot} />
                <Footer links={SamIotFooterLinks} board={BOARDS.samIot} />
            </div>
        );
    }
}
