import TopNav from '../../components/TopNav/TopNav';
import { CLOUDS } from '../../Constants';

export default function NotFound() {
    return (
        <div className="frontpage landingpage">
            <TopNav cloud={CLOUDS.none} />
            <div className="content-wrapper">
                <h2>Not Found</h2>
                <h3>Sorry you didn't find what you were looking for...</h3>
            </div>
        </div>
    );
}
