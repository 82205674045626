import './MoreTutorials.scss';
import {Pic32mzw1LatestFWLink, Wfi32IotLatestFWLink} from './Pic32mzw1Links';
import chip from '../../images/chip.png';
import { OutboundLink } from '../../components/Links/Links';

export default function MoreTutorials() {
    return (
        <section className='more'>
            <section className='content-wrapper tile'>
                <h3>Explore more tutorials in GitHub</h3>
                <ol>
                    <li>
                        <strong>Connect the board to your own AWS account</strong>
                        <p>
                            The board is registered to Microchip AWS account out-of-box. You can transfer the board
                            from Microchip AWS account to your own aws account by following the relevant steps for  
                            {' '}
                            <OutboundLink to="https://github.com/MicrochipTech/PIC32MZW1_Curiosity_OOB/blob/master/README.md" eventLabel="GitHub docs for PIC32MZW1">Curiosity</OutboundLink>
                            {' / '}
                            <OutboundLink to="https://github.com/MicrochipTech/WFI32-IoT/blob/master/README.md" eventLabel="GitHub docs for WFI32-IoT">WFI32-IoT</OutboundLink>
                            {' '}board.
                        </p>
                    </li>
                    <li>
                        <strong>Add voice commands</strong>
                        <p>
                            You can easily control your board with a smart speaker by adding voice control commands
                            in your AWS account. Please follow detailed instructions for
                            {' '}
                            <OutboundLink to="https://github.com/MicrochipTech/PIC32MZW1_Curiosity_OOB/blob/master/README.md#voice-control" eventLabel="GitHub docs for PIC32MZW1">Curiosity</OutboundLink>
                            {' / '}
                            <OutboundLink to="https://github.com/MicrochipTech/WFI32-IoT/blob/master/README.md" eventLabel="GitHub docs for WFI32-IoT">WFI32-IoT</OutboundLink>
                            {' '}board.
                    </p>
                    </li>
                    <li>
                        <strong>Full getting-started user guide with more details</strong>
                        <p>
                            All the other instructions are available in the getting-started webpage at GitHub. Please follow
                            the relevant guide for
                            {' '}
                            <OutboundLink to="https://github.com/MicrochipTech/PIC32MZW1_Curiosity_OOB" eventLabel="GitHub docs for PIC32MZW1">Curiosity</OutboundLink>
                            {' / '}
                            <OutboundLink to="https://github.com/MicrochipTech/WFI32-IoT" eventLabel="GitHub docs for WFI32-IoT">WFI32-IoT</OutboundLink>
                            {' '}board.
                        </p>
                    </li>
                </ol>
            </section>
            <section className="content-wrapper">
                <div className="more-extra">
                    <div className="tile">
                        <h4>Stay Up to Date</h4>
                        <p>Download the hex file and program it to your device to stay up to date.</p>
                        <div className="btn red-inverted">
                            <OutboundLink to={Pic32mzw1LatestFWLink} eventLabel={'Get latest fw: ' + Pic32mzw1LatestFWLink}>Get latest Firmware for Curiosity board</OutboundLink>

                        </div>
                        <div className="btn red-inverted">
                            <OutboundLink to={Wfi32IotLatestFWLink} eventLabel={'Get latest fw: ' + Wfi32IotLatestFWLink}>Get latest Firmware for WFI32-IoT board</OutboundLink>
                        </div>
                    </div>
                    <div className="tile">
                        <h4>Build a solution at scale</h4>
                        <div className="grid">
                            <div className="img">
                                <img src={chip} alt="chip" />
                            </div>
                            <div className="text">
                                <p>Designing a hardware solution?</p>
                                <OutboundLink to="https://www.microchip.com/clientsupport" eventLabel="Talk to ecpert link">Talk to an Embedded Design Expert</OutboundLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    );
}
