import { ReactElement, useEffect, useRef, useState } from 'react';
import TopNav from '../../components/TopNav/TopNav';
import { BOARDS, CLOUDS } from '../../Constants';
import { CellularMiniFooterLinks } from './AvrIotCellularMiniLinks';
import { useLocation } from 'react-router-dom';
import { OutboundLink } from 'react-ga';
import cellularMiniBoard from '../../images/avr-iot-cellular-mini.png';
import activateButtonImg from '../../images/truphone-activate-button.png';
import simRemovableImg from '../../images/removable-sim.svg';
import boardWithAntenna from '../../images/mini-board-with-antenna.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faLightbulb, faBugSlash, faWrench, faSimCard, faArrowLeft, faChessRook } from '@fortawesome/free-solid-svg-icons';
import { Footer } from '../../components/Footer/Footer';
import { DeviceManager, deviceManagerFactory } from '../../utils/DeviceManagerFactory';
import './AvrIotCellularMini.scss';
import Collapse, { Panel } from 'rc-collapse';
import { ExpandIcon } from '../../components/Expanders/Icon';
import MarkdownContent from './MarkdownContent';
import BoardStatusMonitor from './BoardStatusMonitor';
import { ProgressBarMini, ProgressBarMiniProps, STAGES } from '../../components/ProgressBar/ProgressBarMini';
import DragndropGif from '../../images/dragndrop.gif';
import { ArduinoDashboard } from './ArduinoDashboard';
import { FreeRTOSDashboard } from './FreeRTOSDashboard';

type AvrIotCellularMiniPageProps = {

};

export default function AvrIotCellularMiniPage(props: AvrIotCellularMiniPageProps) {
    const [deviceManager, setDeviceManager] = useState<DeviceManager | null>(null);
    const [showDashboard, setShowDashboard] = useState<boolean>(false);
    const [stage, setStage] = useState<STAGES>(STAGES.board);
    const [data, _setData] = useState<any[]>([]);
    const [demoName, setDemoName] = useState<string>('arduinodemo');
    const dataRef = useRef<any[]>(data);
    const setData = (data: any[]) => {
        dataRef.current = data;
        _setData(data);
    };

    const [streamTimeout, _setStreamTimeout] = useState<NodeJS.Timeout | null>(null);
    const streamTimeoutRef = useRef<NodeJS.Timeout | null>(streamTimeout);
    const setStreamTimeout = (timeout: NodeJS.Timeout | null) => {
        streamTimeoutRef.current = timeout;
        _setStreamTimeout(timeout);
    };

    const [isStreaming, setIsStreaming] = useState<boolean>(false);

    const location = useLocation();
    const search = new URLSearchParams(location.search);
    const id = search.get('id');
    const thingName = id ? id.toLowerCase() : '';


    useEffect(() => {
        if (!deviceManager && thingName.length > 0) {
            let dm = deviceManagerFactory(CLOUDS.aws, BOARDS.avrIot);
            dm?.setDevice(thingName);

            dm?.setOnMessage((newData: any) => {

                if (newData && newData.type === 'data' && newData.data) {
                    const stateData = dataRef.current;
                    const d = stateData.slice(-20).concat(newData.data);
                    setData(d);

                    if (newData.app_id === 'FreeRTOS_demo') {
                        setDemoName('freertosdemo');
                    } else {
                        setDemoName('arduinodemo');
                    }
                }

                setIsStreaming(true);
                setStage((prevState) => {
                    if (prevState === STAGES.board) {
                        return STAGES.cloud;
                    } else {
                        return prevState;
                    }
                });

                if (streamTimeoutRef.current) {
                    console.log('Clear timeout');
                    clearTimeout(streamTimeoutRef.current);
                }

                const timeout = setTimeout(() => {
                    console.log('ffStreaming:', false);
                    setIsStreaming(false);
                    setStage(STAGES.board);
                }, 70 * 1000);
                setStreamTimeout(timeout);
            });

            dm?.connect();

            setDeviceManager(dm);
        }
    }, [deviceManager, thingName, data, streamTimeout, stage]);

    function handleShowDashboardClick() {
        if (thingName.length > 0) {
            setShowDashboard(true);
            setStage(STAGES.docs);
        }
    }

    function handleSubmit(data: any) {
        deviceManager?.sendDeviceConfig(data);
    }

    const hasId = thingName.length > 0;

    return (
        <div className="cellular-mini landingpage">
            <TopNav cloud={CLOUDS.aws} />
            <Welcome stage={stage} inset={<></>} />

            {!showDashboard && <Overview onShowDashboardClick={handleShowDashboardClick} hasId={true} stage={stage} />}

            {showDashboard && demoName === 'arduinodemo' && <ArduinoDashboard thingName={thingName} data={data} cloud={CLOUDS.aws} isStreaming={isStreaming} onSubmit={handleSubmit} />}
            {showDashboard && demoName === 'freertosdemo' && <FreeRTOSDashboard thingName={thingName} data={data} cloud={CLOUDS.aws} isStreaming={isStreaming} onSubmit={handleSubmit} />}
            {showDashboard && <NextSteps />}

            {hasId &&
                <div className='overview--monitor'>
                    <div className="content-wrapper">
                        <BoardStatusMonitor usbVendorId={0x03eb} baudRate={115200} />
                    </div>
                </div>
            }


            {showDashboard &&
                <p className='content-wrapper'>
                    <button className='as-link' onClick={() => { setShowDashboard(false); setStage(STAGES.cloud); }}><FontAwesomeIcon icon={faArrowLeft} /> Back</button>
                </p>
            }
            <Footer links={CellularMiniFooterLinks()} board={BOARDS.cellularMini} />
        </div >
    );
}


type WelcomeProps = {
    inset: ReactElement;
    stage: STAGES;
}

function Welcome(props: WelcomeProps) {
    // Load the markdown welcome message
    // eslint-disable-next-line import/no-webpack-loader-syntax
    const markdownContent = require('!!raw-loader!./markdown/header.md');

    let pbProps: ProgressBarMiniProps = {
        cloud: CLOUDS.aws,
        stage: props.stage,
        active: props.stage !== STAGES.docs,
        flash: props.stage === STAGES.docs
    };

    const renderHeadline = (): JSX.Element => {
        if (props.stage === STAGES.docs) {
            return <h2>AVR-IoT Cellular Mini Sandbox Demo</h2>;
        } else {
            return <h2>AVR-IoT Cellular Mini On-Boarding</h2>;
        }
    };

    return (
        <div className="welcome">
            <div className="content-wrapper">
                <div className="content">
                    <div className="center">
                        {renderHeadline()}
                        <MarkdownContent content={markdownContent.default} />
                    </div>
                    {props.inset}
                </div>
                <ProgressBarMini {...pbProps} />
            </div>
        </div>
    );
}

function Icon(props: object) {
    let { isActive } = props as { isActive: boolean };
    return ExpandIcon(isActive, '#4285F4', 2, 'transparent');
}

type OverviewProps = {
    onShowDashboardClick: () => void;
    hasId: boolean;
    stage: STAGES;
}

function SucessTopView(props: { stage: STAGES, onShowDashboardClick: () => void; }) {
    if (props.stage === STAGES.cloud) {
        return (
            <div style={{ textAlign: 'center' }}>
                <div onClick={props.onShowDashboardClick} style={{ textAlign: 'center' }} className="btn green-big">
                    <span >Board Connection Established! Click Here to Proceed</span>
                </div>
            </div>
        );
    } else {
        return <span />;
    }
}

function Overview(props: OverviewProps) {
    return (
        <div className='overview--no-bot-padding'>
            <div className='content-wrapper'>
                <p><i>
                    The following steps are designed to guide you through the process of connecting your board.
                    If you want to skip the said process, see the documentation at <a href="https://iot.microchip.com/docs">https://iot.microchip.com/docs</a>
                </i></p>
                {props.hasId &&
                    <div>
                        <SucessTopView stage={props.stage} onShowDashboardClick={props.onShowDashboardClick} />
                        <section>

                            <Collapse accordion={true}
                                className="rounded"
                                defaultActiveKey="-1"
                                expandIcon={Icon}
                            >
                                <Panel
                                    header={<span><FontAwesomeIcon className='header-icon' icon={faWrench} /> Hardware Preparation</span>}
                                    headerClass="rounded-header"
                                    className="rounded"
                                >

                                    <div className='row'>

                                        <div className='column column-left'>

                                            <p>The board must be prepared before first use</p>
                                            <p>
                                                <ol className="step-list">
                                                    <li><b>Cut Power:</b> Disconnect the board's USB cable</li>
                                                    <li><b>Insert SIM:</b> Insert the bundled Truphone SIM Card in the slot on the backside of the board</li>
                                                    <li><b>Attach Antenna:</b> Connect the external antenna at the very end of the board. It should click in place. It should not require a lot of force</li>
                                                    <li><b>Reconnect power:</b> Reconnect the board to the computer with the USB cable, open the CURIOSITY drive and double click on the <b>CLICKME.HTM</b> file.</li>
                                                </ol>
                                            </p>
                                        </div>
                                        <div className='column column-right'>
                                            <img alt="Illustration of a SIM Card" src={boardWithAntenna} />
                                        </div>
                                    </div>

                                </Panel>
                                <Panel
                                    header={<span><FontAwesomeIcon className='header-icon' icon={faChessRook} /> Provision the Board</span>}
                                    headerClass="rounded-header"
                                    className="rounded"
                                >

                                    <div className='row'>
                                        <p>In order for the board to function correctly, you must run the IoT Provisioning Tool to install all the latest settings and firmwares on the board</p>
                                        <div style={{justifyContent: 'center', display: 'flex'}}>
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/0r3m3hsQcoU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                        </div>
                                        <h3>Summary:</h3>
                                        <p>
                                            <ol className="step-list">
                                                <li><b>Download the Tool:</b> Download the tool from GitHub <a href="https://github.com/microchip-pic-avr-tools/iotprovision-bin">https://github.com/microchip-pic-avr-tools/iotprovision-bin</a></li>
                                                <li><b>Run the tool</b>: .\iotprovision-bin.exe -c aws -m sandbox</li>
                                            </ol>
                                        </p>
                                    </div>

                                </Panel>
                                <Panel
                                    header={<span><FontAwesomeIcon className='header-icon' icon={faDownload} /> Upgrade the Board Firmware</span>}
                                    headerClass="rounded-header"
                                    className="rounded"
                                >
                                    <div className='row'>
                                        <div className='column column-left'>
                                            <p>
                                                The board is pre-programmed with an on-boarding / sandbox firmware, which is designed to interact with this page and get you up and running. For the optimal experience, download the newest version of said firmware.
                                            </p>
                                            <p>
                                                When downloaded, drag and drop the <i>.hex</i> file to the CURIOSITY drive as shown in the .gif below.
                                            </p>
                                            <p style={{ textAlign: 'center' }}>
                                                <div className="btn red">
                                                    <OutboundLink eventLabel='Download the latest on-boarding firmware' to='https://github.com/microchip-pic-avr-solutions/avr-iot-cellular-arduino-library/releases/latest/download/sandbox.hex' target='_blank'>Download the latest on-boarding firmware</OutboundLink>
                                                </div>
                                            </p>
                                            <p style={{ textAlign: 'center' }}>
                                                <img alt="GIF of drag and dropping the hex file to the curiosity drive" src={DragndropGif} />
                                            </p>
                                        </div>
                                        <div className='column column-right'>
                                            <img src={cellularMiniBoard} alt="AVR-IoT Cellular Mini board" />
                                        </div>
                                    </div>
                                </Panel>
                                <Panel
                                    header={<span><FontAwesomeIcon className='header-icon' icon={faSimCard} /> Register &amp; Activate Your SIM Card</span>}
                                    headerClass="rounded-header"
                                    className="rounded"
                                >
                                    <div className='row'>
                                        <div className='column column-left'>
                                            <p>Bundled with the box you should find a Truphone SIM Card. The card <i>has</i> to be activated in order to use it.</p>
                                            <ol className="step-list">
                                                <li>Verify you are in a region with LTE-M Coverage from Truphone on <a target="_blank" rel="noreferrer" href="https://web.truphone.com/things/plans/coverage/?feature=LTE-M">the Truphone website</a></li>
                                                <li>Go to <a target="_blank" rel="noreferrer" href="https://web.truphone.com/connectit/">https://web.truphone.com/connectit/</a> and start the registration</li>
                                                <li>Create an account and enter your SIM's ICCID and PUK</li>
                                                <li>Activate the card in the <a target="_blank" rel="noreferrer" href="https://iot.truphone.com/sims/">Truphone IoT Portal</a></li>
                                                <ul>
                                                    <li>Navigate to the <a href="https://iot.truphone.com/sims/">Truphone IoT Portal</a> and select your SIM</li>
                                                    <li>Scroll down to the <i>Status</i> section. If the "SIM Card" status is not <span style={{ color: '#70ca63' }}>Active</span>, click the green "Activate" button.</li>
                                                </ul>
                                            </ol>
                                        </div>
                                        <div className='column column-right'>
                                            <img alt="Illustration of a SIM Card" src={simRemovableImg} />
                                        </div>
                                        <div style={{ textAlign: 'center' }}>
                                            <img alt="Screenshot of Activation Button" src={activateButtonImg} />
                                        </div>
                                        <div>
                                            For more information on the Truphone IoT Portal, see <a href="https://docs.things.truphone.com/docs/overview">https://docs.things.truphone.com/docs/overview</a>
                                        </div>
                                    </div>
                                </Panel>
          


                                <Panel
                                    header={<span><FontAwesomeIcon className='header-icon' icon={faLightbulb} /> Connect to the Network</span>}
                                    headerClass="rounded-header"
                                    className="rounded"
                                >
                                    <p>After the SIM card has been activated, the board should automatically connect to the Microchip Sandbox. </p>
                                    <p>
                                        <b>Allow up to five minutes of initial connection setup</b>.
                                        Depending on the board's location, different cellular operators are used.
                                        These different operators use different frequency bands, which must be searched. This process can take a couple of minutes. Upon a successful connection, the band settings are saved.
                                    </p>
                                    <p>
                                        Upon a successful connection, the above progress bar will advance.
                                        Check the LEDs on the board for the board state. The connection to Truphone might take a little while on the first connection.
                                    </p>
                                    <p>
                                        <ul>
                                            <li><span style={{ color: 'blue' }}>CELL</span>: Blinks during connection attempts / band search. On if a connection to the cellular network has been established</li>
                                            <li><span style={{ color: 'green' }}>CONN</span>: On if a connection to the Microchip Sandbox has been established</li>
                                            <li><span style={{ color: '#d4a715' }}>DATA</span>: Toggles whenever data is sent to the Microchip Sandbox</li>
                                        </ul>
                                    </p>
                                    <p>
                                        You can also use the <i>Board Serial Output</i> below to see debug messages from the board. These messages are read through USB.
                                    </p>
                                    <p>
                                        <b>The sandbox detects a connection when it receives a heartbeat message from the board. This heartbeat is sent every minute. Click SW0 on the board to force a heartbeat.</b>
                                    </p>
                                </Panel>
                                <Panel
                                    header={<span><FontAwesomeIcon className='header-icon' icon={faBugSlash} /> Debugging</span>}
                                    headerClass="rounded-header"
                                    className="rounded"
                                >
                                    <h3>Debug Checklist</h3>
                                    <p>
                                        If the board is unable to connect after following all the previous steps, verify the following:
                                        <ul className="step-list">
                                            <li>
                                                The SIM Card is activated
                                            </li>
                                            <li>
                                                You are located in a region with LTE-M coverage. See <a href="https://web.truphone.com/things/plans/coverage/">Truphone's website</a> for an overview of coverage
                                            </li>
                                            <li>
                                                At least five minutes have passed since powering on the board to allow for the local frequency band search.
                                            </li>
                                            <li>
                                                Use the <i>Board Serial Output</i> below to see if the board outputs any error messages
                                            </li>
                                            <li>
                                                The board is running the latest on-boarding firmware (see step #1)
                                            </li>
                                            <li>
                                                Reset the board by clicking the <i>SW1</i> button on the board
                                            </li>
                                        </ul>
                                    </p>
                                    <h3>IoT Provisioning Tool</h3>
                                    <p>
                                        If the issue persist after attempting all of the above, try running the IoT Provisioning Tool to reset the board to its factory state. See <b><a target="_blank" rel="noreferrer" href="https://iot.microchip.com/docs/arduino/userguide/provisioning#factory-reset">https://iot.microchip.com/docs/arduino/userguide/provisioning#factory-reset</a></b>
                                    </p>
                                </Panel>
                            </Collapse>


                        </section>
                        <section>
                            <SucessTopView stage={props.stage} onShowDashboardClick={props.onShowDashboardClick} />

                        </section>

                    </div>

                }
            </div >
        </div >
    );
}

function NextSteps() {
    return (
        <section className='next-steps'>
            <section className='content-wrapper'>
                <div className="content" style={{ marginBottom: '30px' }}>
                    <h2>Next Steps</h2>
                    <p>When you are ready to program your own application, visit the documentation page
                        at <a href="https://iot.microchip.com/docs">https://iot.microchip.com/docs</a>!
                    </p>
                </div>

                <div style={{ textAlign: 'center' }}>
                    <a href="https://iot.microchip.com/docs" className="btn red-inverted">Go to the Documentation</a>
                </div>
            </section>
        </section>
    );
}
