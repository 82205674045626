import { MouseEvent, useState } from 'react';
import ToggleControl from './ToggleControl';
import './MultiControlPane.scss';
import SliderControl from './SliderControl';
import TextControl from './TextControl';

type ToggleData = {
    id: number;
    label: string;
    checked: boolean;
}

type SliderData = {
    id: number;
    label: string;
    value: number;
}

type TextData = {
    id: number;
    label: string;
    value: string;
}

type MultiControPaneProps = {
    defaultToggles: string[];
    onSubmit: (data: any) => void
};

export default function MultiControlPane(props: MultiControPaneProps) {
    const [toggleId, setToggleId] = useState(0);
    const [sliderId, setSliderId] = useState(0);
    const [textId, setTextId] = useState(0);

    const [toggles, setToggles] = useState<ToggleData[]>(props.defaultToggles.map((l, i) => {
            return {id: -1-i, label: l, checked: false };
        })
    );
    const [sliders, setSliders] = useState<SliderData[]>([]);
    const [texts, setTexts] = useState<TextData[]>([]);
    
    function getNextToggleId() {
        setToggleId(toggleId + 1);
        return toggleId;
    }

    function getNextSliderId() {
        setSliderId(sliderId + 1);
        return sliderId;
    }

    function getNextTextId() {
        setTextId(textId + 1);
        return textId;
    }


    function submitHandler(event: MouseEvent) {
        event.preventDefault();
        let data: any = {};

        toggles.forEach(t => {
            data[t.label] = t.checked ? 1 : 0;
        });
        
        sliders.forEach(s => {
            data[s.label] = s.value;
        });

        texts.forEach(t => {
            data[t.label] = t.value;
        });

        props.onSubmit(data);
    }

    function handleAddToggle() {
        let newToggles = toggles.slice();
        newToggles.push({id: getNextToggleId(), label: 'toggle' + toggleId, checked: false });
        setToggles(newToggles);
    }

    function handleToggleChange(id: number, value: boolean) {
        const newToggles = toggles.slice();
        const toggle = newToggles.find(t => t.id === id);
        if (toggle) {
            toggle.checked = value;
            setToggles(newToggles);
        }
    }

    function handleToggleLabelChange(id: number, value: string) {
        const newToggles = toggles.slice();
        const toggle = newToggles.find(t => t.id === id);
        if (toggle) {
            toggle.label = value;
            setToggles(newToggles);
        }
    }

    function handleDeleteToggle(id: number) {
        const newToggles = toggles.slice();
        const toggle = newToggles.find(t => t.id === id);
        if (toggle) {
            const index = newToggles.indexOf(toggle, 0);
            if (index > -1) {
                newToggles.splice(index, 1);
                setToggles(newToggles);
            }
        }
    }

    function handleAddSlider() {
        let newSliders = sliders.slice();
        newSliders.push({id: getNextSliderId(), label: 'slider' + sliderId, value: 50 });
        setSliders(newSliders);
    }

    function handleSliderValueChange(id: number, value: number) {
        const newSliders = sliders.slice();
        const slider = newSliders.find(t => t.id === id);
        if (slider) {
            slider.value = value;
            setSliders(newSliders);
        }
    }

    function handleSliderLabelChange(id: number, value: string) {
        const newSliders = sliders.slice();
        const slider = newSliders.find(t => t.id === id);
        if (slider) {
            slider.label = value;
            setSliders(newSliders);
        }
    }

    function handleSliderDelete(id: number) {
        const newSliders = sliders.slice();
        const slider = newSliders.find(t => t.id === id);
        if (slider) {
            const index = newSliders.indexOf(slider, 0);
            if (index > -1) {
                newSliders.splice(index, 1);
                setSliders(newSliders);
            }
        }
    }


    function handleAddText() {
        let newTexts = texts.slice();
        newTexts.push({id: getNextTextId(), label: 'text' + textId, value: '' });
        setTexts(newTexts);
    }

    function handleTextValueChange(id: number, value: string) {
        const newTexts = texts.slice();
        const text = newTexts.find(t => t.id === id);
        if (text) {
            text.value = value;
            setTexts(newTexts);
        }
    }

    function handleTextLabelChange(id: number, value: string) {
        const newSliders = sliders.slice();
        const slider = newSliders.find(t => t.id === id);
        if (slider) {
            slider.label = value;
            setSliders(newSliders);
        }
    }

    function handleTextDelete(id: number) {
        const newTexts = texts.slice();
        const text = newTexts.find(t => t.id === id);
        if (text) {
            const index = newTexts.indexOf(text, 0);
            if (index > -1) {
                newTexts.splice(index, 1);
                setTexts(newTexts);
            }
        }
    }



    let toggleControls = toggles.map(t => 
        <ToggleControl
            key={t.id}
            label={t.label}
            id={t.id}
            checked={t.checked} 
            onClick={handleToggleChange}
            onDelete={handleDeleteToggle}
            onChangeLabel={handleToggleLabelChange}
        />
    );

    let sliderControls = sliders.map(s =>
        <SliderControl
            key={s.id}
            label={s.label}
            id={s.id}
            value={s.value}
            onValueChange={handleSliderValueChange}
            onLabelChange={handleSliderLabelChange}
            onDelete={handleSliderDelete}
        />
    );

    let textControls = texts.map(t =>
        <TextControl
            key={t.id}
            label={t.label}
            id={t.id}
            value={t.value}
            onValueChange={handleTextValueChange}
            onLabelChange={handleTextLabelChange}
            onDelete={handleTextDelete}
        />
    );

    return (
        <div className="content-wrapper">
            <div className="multicontrolpane">
                <h3>Control Your Device</h3>

                <h4>Toggle</h4>
                {toggleControls}
                <button className="btn round" onClick={handleAddToggle}>+</button>

                <h4>Slider</h4>
                {sliderControls}
                <button className="btn round" onClick={handleAddSlider}>+</button>

                <h4>Text</h4>
                {textControls}
                <button className="btn round" onClick={handleAddText}>+</button>

                <div className="submit">
                    <button className="btn red" onClick={ submitHandler }>Send to device</button>
                </div>
            </div>
        </div>
    );
}
