import classnames from 'classnames';
import checkMark from '../../images/connected-check.svg';
import { Pulsar } from './Pulsar';

export type SymbolProps = {
    icon: any;
    color: string;
    flashing: boolean;
    active: boolean;
    link?: string;
}

function Checkmark() {
    return (<img src={checkMark} alt="check" className="checkmark" />);
}

function SymbolDiv(props: SymbolProps) {
    const pulsar = props.flashing ? <Pulsar color={props.color} /> : null;
    const active = props.active ? props.color : 'gray';
    const checkmark = props.active ? <Checkmark /> : null;

    return (
        <div className={classnames('symbol', props.color)}>
            {pulsar}
            <svg viewBox="0 0 76 76" height="76" width="76" fill="none" className={active}>
                <circle cx="38" cy="38" r="30" strokeWidth="4" fill="white" />
            </svg>
            <img src={props.icon} alt="" />
            {checkmark}
        </div>
    );
}


export function Symbol(props: SymbolProps) {
    if (props.link !== undefined) {
        return (
            <a href={props.link}>
                <SymbolDiv {...props} />
            </a>
        );
    } else {
        return <SymbolDiv {...props} />;
    }
}