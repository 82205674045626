import classnames from 'classnames';

type ProgressLineProps = {
    progress: string
    color: string
    mini?: boolean
}

export function ProgressLine(props: ProgressLineProps) {
    let className = 'progressbar';
    if (props.mini) {
        className = 'progressbar-mini';
    }
    return (
        <div className={className}>
            <div className="white">
                <div className={classnames(props.progress, props.color)} />
            </div>
        </div>
    );
}
