import { Links, Link } from '../../components/Footer/Footer';
import { CLOUDS } from '../../Constants';

export const PicIotBuyLink         = 'https://www.microchipdirect.com/dev-tools/EV54Y39A?allDevTools=true';
export const PicIotLatestAwsFwLink = 'https://github.com/microchip-pic-avr-solutions/pic-iot-aws-sensor-node/releases/latest';
export const PicIotLatestGcpFwLink = 'https://github.com/MicrochipTech/PIC-IoT-WG-Sensor-Node';

export function PicIotFooterLinks(): Links[] {
    return [
        {
            header: 'Rapid Development Tools',
            links: [
                {
                    text: 'MPLAB Code Configurator',
                    url:  'https://microchip.com/mcc'
                },{
                    text: 'MPLAB XPRESS',
                    url:  'http://www.microchip.com/xpress'
                },{
                    text: 'MPLAB Harmony v3',
                    url:  'https://www.microchip.com/en-us/development-tools-tools-and-software/embedded-software-center/mplab-harmony-v3'
                }
            ]
        }, {
            header: 'Other IoT Solutions & Architecture',
            links: [
                {
                    text: 'All Microchip IoT Solutions',
                    url:  'https://www.microchip.com/en-us/solutions/internet-of-things'
                }
            ]
        }
    ];
}

export function PicIotDocumentationLinks(cloud: CLOUDS): Link[] {
    let awsIotDeveloperGuideText : string | null = 'Microchip IoT Developer Guides for AWS';
    let awsAppUserGuideText      : string | null = 'Application User Guide - AWS';
    let awsSourceCodeUrlText     : string | null = 'Embedded Source Code - AWS';

    const awsIotDeveloperGuide   : string | null = 'https://github.com/microchip-pic-avr-solutions/microchip-iot-developer-guides-for-aws';
    const awsAppUserGuide        : string | null = 'https://github.com/microchip-pic-avr-solutions/pic-iot-aws-sensor-node';
    const awsSourceCodeUrl       : string | null = 'https://github.com/microchip-pic-avr-solutions/pic-iot-aws-sensor-node';

    switch (cloud) {
        case CLOUDS.aws:
            awsAppUserGuideText  = 'Application User Guide';
            awsSourceCodeUrlText = 'Embedded Source Code';
            break;

        case CLOUDS.gcp:
            awsIotDeveloperGuideText = null;
            awsSourceCodeUrlText = null;
            awsAppUserGuideText  = null;
            break;
    }

    return [
        {
            text: awsIotDeveloperGuideText,
            url: awsIotDeveloperGuide
        },{
            text: awsAppUserGuideText,
            url:  awsAppUserGuide
        },{
            text: 'Hardware Users Guide',
            url:  'https://www.microchip.com/DS50002964'
        },{
            text: 'Pin Diagram',
            url:  'https://www.microchip.com/mymicrochip/filehandler.aspx?ddocname=en609453'
        },{
            text: awsSourceCodeUrlText,
            url:  awsSourceCodeUrl,
        }
    ].filter((l) => l.text);
}
