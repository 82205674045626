import { EditText, onSaveProps } from 'react-edit-text';
import TextBox from './TextBox';
import DeleteButton from './DeleteButton';
import './TextControl.scss';

type TextControlProps = {
    label: string;
    id: number;
    value: string;
    onValueChange: (id: number, value: string) => void;
    onLabelChange: (id: number, label: string) => void;
    onDelete: (id: number) => void;
};

export default function TextControl(props: TextControlProps) {
    const handleLableSave = (saveProps: onSaveProps) => {
        let { value } = saveProps;
        props.onLabelChange(props.id, value);
    };

    return (
        <div className="TextControl">
            <EditText
                className="label"
                name={props.label}
                defaultValue={props.label}
                placeholder='Enter a name'
                onSave={handleLableSave}
                style={{width: '200px'}} inline
            />
            <TextBox className="textvalue" onChange={(event) => props.onValueChange(props.id, event.target.value)} value={props.value} />
            <DeleteButton onClick={ () => props.onDelete(props.id) } />
        </div>
    );
}
