import { Link } from 'react-router-dom';
import { BOARDS, CLOUDS } from '../../Constants';
import './Overview.scss';
import winc1500 from '../../images/atwinc1500.png';
import avriotvideo from '../../images/AvrIotWX.m4v';
import piciotvideo from '../../images/PicIotWX.mp4';
import samIotBoard from '../../images/sam-iot-board.png';

export type OverviewPropsType = {
    board: BOARDS;
    cloud: CLOUDS;
};

function BlankLink(url: string, text: string): JSX.Element {
    return <a href={url} target="_blank" rel="noopener noreferrer" className="overview-link">{text}</a>;
}

function genericConfigTool(configuratorLink: JSX.Element) {
    return <p>
        Using the power of the {configuratorLink} tool, a free, professional, rapid
        development tool (code generator) you can now add Google Cloud connectivity
        to new and existing projects with a click of your mouse.
    </p>;
}

function harmonyConfigTool() {
    return <p>
        MPLAB Harmony v3 is a fully integrated embedded software development framework
        that provides flexible and interoperable software modules to simplify the
        development. This framework support for SAM families of Arm Cortex- M based
        devices. Using the Using the power of
        the {BlankLink('https://www.microchip.com/mplab/mplab-harmony', 'MPLAB Harmony v3')}, a free,
        professional, rapid development tool (with code generator) you can now add
        Google Cloud connectivity to new and existing projects with a click of your mouse.
    </p>;
}

function provisionBox(provisionlink: string) {
    return <div className="provision">
        <div className="inset">
            <h3>Try other cloud solutions</h3>
            <p>The board can be provisioned for various cloud solutions.</p>
            <div className="btn red center">
                <Link className="App-link" to={provisionlink}>See more</Link>
            </div>
        </div>
    </div>;
}

function overviewVideo(videoUrl: string) {
    return <div className="overview-video">
        <video id="deviceVideo" autoPlay muted loop>
            <source src={videoUrl} type="video/mp4" />
        </video>
    </div>;
}

function simplicityImageTag(url: string) {
    return <div><img src={url} alt="board" height="120px" /></div>;
}

export default function Overview(props: OverviewPropsType) {
    let architecture: string = 'XXX';
    let architectureLearnMoreLink: JSX.Element = (<></>);
    let configuratorTool: JSX.Element = (<></>);
    let analysisSnippet: JSX.Element = (<></>);
    let securitySnippet: JSX.Element = (<></>);
    let gcpMqtt: JSX.Element = (<></>);
    let provisionInset: JSX.Element = (<></>);
    let video: JSX.Element = (<></>);
    let simplicityImage: JSX.Element = (<></>);
    
    switch (props.board) {
        case BOARDS.avrIot:
            architecture = 'AVR';
            architectureLearnMoreLink = BlankLink('https://www.microchip.com/design-centers/8-bit',
                `(Learn more about ${architecture} Microcontrollers)`);
            configuratorTool = genericConfigTool(BlankLink('https://start.atmel.com', 'Atmel START'));
            provisionInset = provisionBox('/avr-iot/provision');
            video = overviewVideo(avriotvideo);
            break;
            
        case BOARDS.picIot:
            architecture = 'PIC';
            architectureLearnMoreLink = BlankLink('https://www.microchip.com/design-centers/8-bit',
            `(Learn more about ${architecture} Microcontrollers)`);
            configuratorTool = genericConfigTool(BlankLink('https://microchip.com/mcc', 'MPLAB Code Configurator'));
            video = overviewVideo(piciotvideo);
            provisionInset = provisionBox('/pic-iot/provision');
            break;

        case BOARDS.samIot:
            architecture = 'SAM';
            architectureLearnMoreLink = BlankLink('https://www.microchip.com/design-centers/32-bit/sam-32-bit-mcus/sam-d-mcus',
                `(Learn more about ${architecture} Microcontrollers)`);
            configuratorTool = harmonyConfigTool();
            simplicityImage = simplicityImageTag(samIotBoard);
            break;
    }

    switch (props.cloud) {
        case CLOUDS.aws:
            analysisSnippet = (<>{BlankLink('https://aws.amazon.com/iot-analytics', 'AWS IoT Analytics')} and {' '}
                {BlankLink('https://aws.amazon.com/ai', 'Amazon AI')} services process it.</>);
            
            securitySnippet = (<p>By leveraging the power of the
                {BlankLink('https://www.microchip.com/design-centers/security-ics/trust-platform/trust-go/trust-go-google-iot-core',
                'ATECC608A secure element')} we make no compromises on the design security. With the convenience of the 
                {BlankLink('https://www.microchip.com/design-centers/security-ics/trust-platform', 'Trust&GO technologies')},
                your devices can be pre-provisioned for the Google Cloud (out of box) while you scale your production to the millions.</p>);
            break;

        case CLOUDS.gcp:
        case CLOUDS.none:
        default:
            analysisSnippet = (<>the powerful Google Cloud analytics tools process it for
                you. {BlankLink('https://cloud.google.com/products/big-data/', '(Learn more about Google Analytics tools)')}</>);

            securitySnippet = (<p>By leveraging the power of the {}
                {BlankLink('https://www.microchip.com/design-centers/security-ics/trust-platform/trust-go/trust-go-google-iot-core',
                'ATECC608A secure element')} we make no compromises on the design security. Keep your
                private keys private, offload complex elliptic curve cryptography for maximum
                power efficiency and simply enable your microcontroller of choice to communicate
                securely with the {BlankLink('https://cloud.google.com/','Google Cloud')}. With the convenience of the {}
                {BlankLink('https://www.microchip.com/design-centers/security-ics/trust-platform', 'Trust&GO technologies')},
                your devices can be pre-provisioned for the Google Cloud (out of box) while you scale your production to the millions.</p>);

            gcpMqtt = (<p>Google IoT Core Cloud uses the popular MQTT publish subscribe mechanism, but employs a
                clever security mechanism based on the JSON Web Token (or JWT) to make the
                authentication/login process efficient even on the smallest 8-bit microcontroller architectures.</p>);
            break;
    }


    return (
        <section className='overview'>
            <section className='content-wrapper'>
                <div className="content">
                    <div className="left">
                        <h3>Simplicity</h3>
                        <p>The {architecture} microcontroller architecture has been widely recognized as one
                            of the most effective choices for embedded control
                            design. {architectureLearnMoreLink}
                        </p>
                        {configuratorTool}
                        <p>No RTOS required, no complex framework knowledge, no fees. With an
                            intuitive, collaborative, multitasking scheme you can publish your sensor
                            node data and have {analysisSnippet}
                        </p>
                    </div>
                    {provisionInset}
                    {simplicityImage}
                </div>

                {video}

                <div className="content">
                    <div className="left">
                        <h3>Security Without Compromises</h3>
                        {securitySnippet}
                    </div>
                    <div className="overview-image">
                        <img alt="ATECC608A element" src="https://www.microchip.com/_images/ics/medium-ATECC608A-SOIC-8.png" />
                    </div>
                </div>

                <div className="content">
                    <div className="left">
                        <h3>Connectivity</h3>
                        <p>
                            The {BlankLink('https://www.microchip.com/en-us/products/wireless-connectivity/embedded-wi-fi/wi-fi-network-controllers','Microchip Wi-Fi® network control module')} offers
                            a complete solution for wireless connectivity. This smart module supports the complete TCP/IP stack
                            and can authenticate your server's connection to the Google Cloud automatically.
                        </p>
                        {gcpMqtt}
                        <p>
                            You don’t need to become an expert in networking protocols to create a robust cloud-connected
                            embedded solution. Take advantage of the MPLAB Code Configurator (MCC) graphical programming tool
                            to quickly configure a secure connection to your chosen cloud provider.
                        </p>
                        
                        {BlankLink('https://www.microchip.com/wwwproducts/en/ATWINC1500','(Learn more about the WINC1510 network control module)')}
                    </div>
                    <div className="overview-image">
                        <img alt="ATWINC1500 module" src={ winc1500 }/>
                    </div>
                </div>
            </section>
        </section>
    );
}
